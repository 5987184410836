import { connect } from 'react-redux';

import { deputySelectors, deputyActions } from '../duck';
import { districtSelectors, districtActions } from '../../districts/duck';
import { townSelectors, townActions } from '../../towns/duck';
import { areaSelectors, areaActions } from '../../areas/duck';

import DeputiesList from './DeputiesList';
import ComponentWithError from '../../shared/HOCs/componentWithError';

const mapStateToProps = state => ({
  isFetching: deputySelectors.isFetchingDeputies(state),
  page: deputySelectors.page(state),
  sizePerPage: deputySelectors.sizePerPage(state),
  totalSize: deputySelectors.totalSize(state),
  errors: deputySelectors.deputiesErrors(state),

  deputies: deputySelectors.deputies(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
});

const mapDispatchToProps = {
  fetchDeputies: deputyActions.fetchDeputies,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithError(DeputiesList));
