// Units list

const isFetchingUnits = ({ units }) => units.isFetching;

const page = ({ units }) => units.page;

const sizePerPage = ({ units }) => units.sizePerPage;

const totalSize = ({ units }) => units.total;

const unitsErrors = ({ units }) => units.errors;

const units = ({ units: v }) => v.items;

// Single unit

const isFetchingUnit = ({ unit }) => unit.isFetching;

const unitErrors = ({ unit }) => unit.errors;

const unit = ({ unit: v }) => v;

export default {
  isFetchingUnits,
  units,
  page,
  totalSize,
  sizePerPage,
  unitsErrors,
  isFetchingUnit,
  unitErrors,
  unit,
};
