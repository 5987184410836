import { createActions, handleActions } from 'redux-actions';

const { fetchSiks, fetchSiksSuccess, fetchSiksFail } = createActions('FETCH_SIKS', 'FETCH_SIKS_SUCCESS', 'FETCH_SIKS_FAIL');

const defaultState = {
  siks: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
};

const siksReducer = handleActions(
  {
    [fetchSiks]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchSiksSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchSiksFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.siks
);

export const reducer = { siks: siksReducer };

export default {
  fetchSiks,
  fetchSiksSuccess,
  fetchSiksFail,
};
