import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { getErrorDisplay } from '../utils';

const PlainInput = ({ externalErrors, form: { errors, touched }, field, ...props }) => {
  let invalid = false;

  if (!Object.prototype.hasOwnProperty.call(props, 'invalid')) {
    invalid = (touched[field.name] && !!errors[field.name]) || !!externalErrors;
  }

  let valid = false;

  if (!Object.prototype.hasOwnProperty.call(props, 'valid')) {
    valid = touched[field.name] && !errors[field.name] && !externalErrors;
  }

  return (
    <Fragment>
      <Input {...props} {...field} invalid={invalid} valid={valid} value={typeof field.value === 'object' ? field.value[field.name] : field.value} />
      {getErrorDisplay(errors[field.name], invalid)}
      {getErrorDisplay(externalErrors, invalid)}
    </Fragment>
  );
};

PlainInput.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.object.isRequired,
  id: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  externalErrors: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
};

PlainInput.defaultProps = {
  externalErrors: '',
  id: '',
  type: '',
  required: false,
  placeholder: '',
  autoComplete: null,
};

export default PlainInput;
