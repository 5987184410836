import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    logoutSuccess: PropTypes.func.isRequired,
    withServerLogout: PropTypes.bool,
  };

  static defaultProps = {
    withServerLogout: false,
  };

  componentDidMount() {
    // If server logout is needed, calling logout() action which will trigger API /logout call.
    // Otherwise, only success logic will be applied (clearing current user local state)
    const { logout, logoutSuccess, withServerLogout } = this.props;
    withServerLogout ? logout() : logoutSuccess();
  }

  render() {
    return <Redirect to="/admin/auth" />;
  }
}

export default Logout;
