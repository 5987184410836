import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardBody } from 'reactstrap';

import UnitsTable from './table';
import { formatTableRespone, toSelectOptionsV2 } from '../../../utils/formatters';

class UnitsList extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    districts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    towns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    areas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    siks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    fetchUnits: PropTypes.func.isRequired,
    fetchDistricts: PropTypes.func.isRequired,
    fetchTowns: PropTypes.func.isRequired,
    fetchAreas: PropTypes.func.isRequired,
    fetchSiks: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchUnits, page, districts, fetchDistricts, towns, areas, siks, fetchTowns, fetchAreas, fetchSiks } = this.props;
    fetchUnits({ page });

    if (!districts.length) fetchDistricts({ page: 1, limit: 1000 });
    if (!towns.length) fetchTowns({ page: 1, limit: 1000 });
    if (!areas.length) fetchAreas({ page: 1, limit: 1000 });
    if (!siks.length) fetchSiks({ page: 1, limit: 1000 });
  }

  handleTableChange = (type, data) => {
    const { fetchUnits } = this.props;
    fetchUnits(formatTableRespone(data));
  };

  render() {
    const { units, page, sizePerPage, totalSize, isFetching, districts, towns, areas, siks } = this.props;
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <b>Руководители</b>
          </CardHeader>
          <CardBody>
            <UnitsTable
              data={units}
              districts={toSelectOptionsV2(districts, 'district', 'id')}
              towns={toSelectOptionsV2(towns, null, 'id')}
              areas={toSelectOptionsV2(areas, null, 'id')}
              siks={toSelectOptionsV2(siks, null, 'id')}
              page={page}
              isLoading={isFetching}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UnitsList;
