import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import NotificationSystem from 'react-notification-system';

import NotificationsService from './services/notifications.service';

import App from './App';
import * as serviceWorker from './serviceWorker';

import store, { persistor } from './redux/store';

import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <React.Fragment>
          <NotificationSystem ref={NotificationsService.notificationRef} />
          <App />
        </React.Fragment>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
