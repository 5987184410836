import { connect } from 'react-redux';

import { officerSelectors, officerActions } from '../duck';
import { districtSelectors, districtActions } from '../../districts/duck';
import { townSelectors, townActions } from '../../towns/duck';
import { areaSelectors, areaActions } from '../../areas/duck';

import OfficersList from './OfficersList';
import ComponentWithError from '../../shared/HOCs/componentWithError';

const mapStateToProps = state => ({
  isFetching: officerSelectors.isFetchingOfficers(state),
  page: officerSelectors.page(state),
  sizePerPage: officerSelectors.sizePerPage(state),
  totalSize: officerSelectors.totalSize(state),
  errors: officerSelectors.officersErrors(state),

  officers: officerSelectors.officers(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
});

const mapDispatchToProps = {
  fetchOfficers: officerActions.fetchOfficers,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithError(OfficersList));
