import React from 'react';
import Router from './router';

import './App.scss';

const App = () => (
  <div className="App">
    <Router />
  </div>
);

export default App;
