import { combineActions, createActions, handleActions } from 'redux-actions';

const {
  fetchProcedures,
  fetchProceduresSuccess,
  fetchProceduresFail,
  fetchProcedure,
  fetchProcedureSuccess,
  fetchProcedureFail,
  editProcedure,
  editProcedureSuccess,
  editProcedureFail,
} = createActions(
  'FETCH_PROCEDURES',
  'FETCH_PROCEDURES_SUCCESS',
  'FETCH_PROCEDURES_FAIL',
  'FETCH_PROCEDURE',
  'FETCH_PROCEDURE_SUCCESS',
  'FETCH_PROCEDURE_FAIL',
  'EDIT_PROCEDURE',
  'EDIT_PROCEDURE_SUCCESS',
  'EDIT_PROCEDURE_FAIL'
);

const defaultState = {
  procedures: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
  procedure: {
    isFetching: false,
    errors: [],
  },
};

const procedureReducer = handleActions(
  {
    [combineActions(fetchProcedure, editProcedure)]: state => ({
      ...state,
      isFetching: true,
    }),
    [combineActions(fetchProcedureSuccess, editProcedureSuccess)]: (state, action) => ({
      ...state,
      ...action.payload.procedure,
      errors: [],
      isFetching: false,
    }),
    [combineActions(fetchProcedureFail, editProcedureFail)]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.procedure
);

const proceduresReducer = handleActions(
  {
    [fetchProcedures]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchProceduresSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchProceduresFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.procedures
);

export const reducer = { procedure: procedureReducer, procedures: proceduresReducer };

export default {
  fetchProcedures,
  fetchProceduresSuccess,
  fetchProceduresFail,
  fetchProcedure,
  fetchProcedureSuccess,
  fetchProcedureFail,
  editProcedure,
  editProcedureSuccess,
  editProcedureFail,
};
