import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import sikOperations from './operations';
import sikActions from './modules';

function* fetchSiks({ payload }) {
  const { errors, response } = yield call(sikOperations.fetchSiks, payload);

  if (response) {
    const siks = _get(response, 'data.siks.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.siks', {});
    yield put(sikActions.fetchSiksSuccess({ items: siks, sizePerPage, page, total }));
  } else {
    yield put(sikActions.fetchSiksFail(errors));
  }
}

function* watchSiksFetch() {
  yield takeLatest(sikActions.fetchSiks, fetchSiks);
}

export default function* watchAll() {
  yield fork(watchSiksFetch);
}
