import { connect } from 'react-redux';

import { authSelectors } from '../auth/duck';

import Layout from './Layout';

const mapStateToProps = state => ({
  user: authSelectors.user(state),
  role: authSelectors.role(state),
});

export default connect(mapStateToProps)(Layout);
