import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, Formik } from 'formik';
import PlainInput from '../../shared/inputs/plain';
import SelectInput from '../../shared/inputs/select';

import validationSchema from './OfficerCreate.schema';

import { validate } from '../../../utils/form';
import StyledDropzone from '../../shared/inputs/dropzone';
import { areaToSelectOptionsV3, areaToDistrict, districtToArea } from '../../../utils/formatters';

class OfficerCreate extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    prevFieldsState: PropTypes.shape().isRequired,
    formStatus: PropTypes.shape().isRequired,
    isFetching: PropTypes.bool.isRequired,

    districts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    towns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    areas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    saveFieldsState: PropTypes.func.isRequired,
    createOfficer: PropTypes.func.isRequired,
    fetchDistricts: PropTypes.func.isRequired,
    fetchTowns: PropTypes.func.isRequired,
    fetchAreas: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.initialValues = {
      id_address_area: '0',
      fio: '',
      podr: '',
      position: '',
      place: '',
      time: '',
      tel: '',
      info: '',
      func: '',
      img: '/assets/user-male-icon.png',
      imgFile: null,
    };
  }

  componentDidMount() {
    const { districts, towns, areas, fetchDistricts, fetchTowns, fetchAreas } = this.props;

    if (!districts.length) fetchDistricts({ page: 1, limit: 1000 });
    if (!towns.length) fetchTowns({ page: 1, limit: 1000 });
    if (!areas.length) fetchAreas({ page: 1, limit: 1000 });
  }

  onSubmit = values => {
    const { areas, towns, saveFieldsState, createOfficer } = this.props;
    saveFieldsState(values);
    const ad = areaToDistrict(areas, towns);
    const newV = { ...values, id_district: _get(ad, values.id_address_area, null) };
    createOfficer({ data: newV, response: this.onSubmitSuccess });
  };

  onSubmitSuccess = officer => {
    const { history } = this.props;
    history.push(`/admin/officer/${officer.id}`);
  };

  render() {
    const { districts, towns, areas, isFetching, formStatus, prevFieldsState } = this.props;

    const areasO = areaToSelectOptionsV3(areas, towns, districts);

    return (
      <div className="animated fadeIn">
        <Formik
          initialValues={this.initialValues}
          validate={validate(validationSchema)}
          onSubmit={this.onSubmit}
          render={({
            values,
            // errors,
            // touched,
            setFieldValue,
            // handleChange,
            // handleBlur,
            handleSubmit,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} noValidate name="officerEditForm">
              <Row>
                <Col md={8}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>Добавить руководителя</strong>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="officer-edit-area">Район</Label>
                        <Field
                          component={SelectInput}
                          externalErrors={prevFieldsState.id_address_area === values.id_address_area ? formStatus.id_address_area : ''}
                          options={areasO}
                          placeholder="Район"
                          id="officer-edit-area"
                          name="id_address_area"
                          value={values.id_address_area}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-fio">ФИО</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.fio === values.fio ? formStatus.fio : ''}
                          id="officer-edit-fio"
                          name="fio"
                          value={values.fio}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-podr">Структурное подразделение</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.podr === values.podr ? formStatus.podr : ''}
                          id="officer-edit-podr"
                          name="podr"
                          value={values.podr}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-position">Должность</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.position === values.position ? formStatus.position : ''}
                          type="textarea"
                          rows="2"
                          id="officer-edit-position"
                          name="position"
                          value={values.position}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-place">Место приема</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.place === values.place ? formStatus.place : ''}
                          id="officer-edit-place"
                          name="place"
                          value={values.place}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-time">День недели; время приема;</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.time === values.time ? formStatus.time : ''}
                          id="officer-edit-time"
                          name="time"
                          value={values.time}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-tel">Телефон</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.tel === values.tel ? formStatus.tel : ''}
                          id="officer-edit-tel"
                          name="tel"
                          value={values.tel}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-info">Дополнительная информация</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.info === values.info ? formStatus.info : ''}
                          type="textarea"
                          rows="2"
                          id="officer-edit-info"
                          name="info"
                          value={values.info}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-func">Функции</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.func === values.func ? formStatus.func : ''}
                          type="textarea"
                          rows="2"
                          id="officer-edit-func"
                          name="func"
                          value={values.func}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Button type="submit" color="primary" className="mr-1" disabled={isFetching || !isValid}>
                          {isFetching ? 'Сохранение...' : 'Сохранить'}
                        </Button>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>Фото</strong>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Field
                          component={StyledDropzone}
                          id="officer-edit-img"
                          name="img"
                          onChange={acceptedFiles => {
                            if (acceptedFiles.length > 0) {
                              if (values.imgFile) URL.revokeObjectURL(values.img);

                              setFieldValue('img', URL.createObjectURL(acceptedFiles[0]));
                              setFieldValue('imgFile', acceptedFiles[0]);
                            }
                          }}
                          classNameType="personal"
                          text1="Кликните или перетащите сюда фотографию"
                          text2="По возможности сделайте предобработку фотографии. Если Вы отправите большое фото, то это может затормозить загрузки у пользователя"
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default OfficerCreate;
