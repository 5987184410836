import { put, call, fork, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import districtOperations from './operations';
import districtActions from './modules';

import NotificationsService from '../../../services/notifications.service';

function* fetchDistricts({ payload }) {
  const { errors, response } = yield call(districtOperations.fetchDistricts, payload);

  if (response) {
    const districts = _get(response, 'data.districts.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.districts', {});
    yield put(districtActions.fetchDistrictsSuccess({ items: districts, sizePerPage, page, total }));
  } else {
    yield put(districtActions.fetchDistrictsFail(errors));
  }
}

function* watchDistrictsFetch() {
  yield takeLatest(districtActions.fetchDistricts, fetchDistricts);
}

export default function* watchAll() {
  yield fork(watchDistrictsFetch);
}
