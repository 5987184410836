import { connect } from 'react-redux';

import { authActions } from '../duck';

import Logout from './Logout';

const mapDispatchToProps = {
  logout: authActions.logout,
  logoutSuccess: authActions.logoutSuccess,
};

export default connect(
  null,
  mapDispatchToProps
)(Logout);
