const rolePermissionMap = {
  managers: {
    view: ['admin'],
  },
  manager: {
    create: ['admin'],
    edit: ['admin'],
  },
  clients: {
    view: ['admin', 'manager'],
  },
  client: {
    create: ['admin', 'manager'],
    edit: ['admin', 'manager'],
  },
  templates: ['admin', 'manager'],
  miners: {
    view: ['admin', 'client'],
    game: ['admin'],
  },

  officers: {
    view: ['admin', 'manager'],
  },
  officer: {
    create: ['admin', 'manager'],
    edit: ['admin', 'manager'],
  },

  deputies: {
    view: ['admin', 'manager'],
  },
  deputy: {
    create: ['admin', 'manager'],
    edit: ['admin', 'manager'],
  },

  procedures: {
    view: ['admin', 'manager'],
  },
  procedure: {
    create: ['admin', 'manager'],
    edit: ['admin', 'manager'],
  },

  units: {
    view: ['admin', 'manager'],
  },
  unit: {
    create: ['admin', 'manager'],
    edit: ['admin', 'manager'],
  },
};

export default rolePermissionMap;
