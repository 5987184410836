import React, { Fragment, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { getErrorDisplay } from '../utils';

const baseStyle = {
  borderWidth: 1,
  borderRadius: 12,
  borderStyle: 'dashed',
  borderColor: '#C5CEE1',
  padding: '0px 10.5px',
};

const activeStyle = {
  borderColor: '#6c6',
  backgroundColor: '#eee',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const StyledDropzone = ({ externalErrors, form: { errors }, field, onChange, ...props }) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      onChange(acceptedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragActive, isDragReject]
  );

  const documentImgPreview = field.value ? new URL(field.value, process.env.REACT_APP_API_URL) : null;
  const { text1, text2, classNameType } = props;

  return (
    <Fragment>
      <section {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {documentImgPreview ? (
          <div className={`${classNameType}-dnd_image ${classNameType}__dnd_image`}>
            <img alt="" src={documentImgPreview} className={`${classNameType}-dnd_image__img`} />
            <div className={`${classNameType}-dnd_image__content`}>
              <small className={`${classNameType}-dnd_image__caption small-caption small-caption_grey`}>
                {text1}
                <br />
                {text2}
              </small>
            </div>
          </div>
        ) : (
          <section className={`${classNameType}-dnd ${classNameType}__dnd`}>
            <div className={`${classNameType}-dnd__content`}>
              <p className={`${classNameType}-dnd__text`}>{text1}</p>
              <small className={`${classNameType}-dnd__caption small-caption small-caption_grey`}>{text2}</small>
            </div>
          </section>
        )}
      </section>
      {getErrorDisplay(errors[field.name])}
      {getErrorDisplay(externalErrors)}
    </Fragment>
  );
};

StyledDropzone.propTypes = {
  onChange: PropTypes.func,
  field: PropTypes.shape({
    onBlur: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape().isRequired,
  classNameType: PropTypes.string.isRequired,
  text1: PropTypes.string,
  text2: PropTypes.string,
  externalErrors: PropTypes.string,
  required: PropTypes.bool,
};

StyledDropzone.defaultProps = {
  onChange: () => {},
  externalErrors: '',
  text1: '',
  text2: '',
  required: false,
};

export default StyledDropzone;
