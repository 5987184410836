import { connect } from 'react-redux';

import { procedureSelectors, procedureActions } from '../duck';
import { districtSelectors, districtActions } from '../../districts/duck';
import { townSelectors, townActions } from '../../towns/duck';
import { areaSelectors, areaActions } from '../../areas/duck';

import ProceduresList from './ProceduresList';
import ComponentWithError from '../../shared/HOCs/componentWithError';

const mapStateToProps = state => ({
  isFetching: procedureSelectors.isFetchingProcedures(state),
  page: procedureSelectors.page(state),
  sizePerPage: procedureSelectors.sizePerPage(state),
  totalSize: procedureSelectors.totalSize(state),
  errors: procedureSelectors.proceduresErrors(state),

  procedures: procedureSelectors.procedures(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
});

const mapDispatchToProps = {
  fetchProcedures: procedureActions.fetchProcedures,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithError(ProceduresList));
