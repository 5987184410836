// Districts list

const isFetchingDistricts = ({ districts }) => districts.isFetching;

const page = ({ districts }) => districts.page;

const sizePerPage = ({ districts }) => districts.sizePerPage;

const totalSize = ({ districts }) => districts.total;

const districtsErrors = ({ districts }) => districts.errors;

const districts = ({ districts: v }) => v.items;

export default {
  isFetchingDistricts,
  districts,
  page,
  totalSize,
  sizePerPage,
  districtsErrors,
};
