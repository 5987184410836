import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import DeputiesTable from './table';
import { formatTableRespone, toSelectOptionsV2, areaToSelectOptionsV2 } from '../../../utils/formatters';

class DeputiesList extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    // errors: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    // location: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    deputies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    districts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    towns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    areas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    fetchDeputies: PropTypes.func.isRequired,
    fetchDistricts: PropTypes.func.isRequired,
    fetchTowns: PropTypes.func.isRequired,
    fetchAreas: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchDeputies, page, districts, fetchDistricts, towns, areas, fetchTowns, fetchAreas } = this.props;
    fetchDeputies({ page });

    if (!districts.length) fetchDistricts({ page: 1, limit: 1000 });
    if (!towns.length) fetchTowns({ page: 1, limit: 1000 });
    if (!areas.length) fetchAreas({ page: 1, limit: 1000 });
  }

  handleCreateClick = () => {
    const { history } = this.props;
    history.push('/admin/deputy/create');
  };

  handleTableChange = (type, data) => {
    const { fetchDeputies } = this.props;
    fetchDeputies(formatTableRespone(data));
  };

  render() {
    const { deputies, page, sizePerPage, totalSize, isFetching, districts, towns, areas } = this.props;
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <b>Депутаты</b>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12} className="gutter-vertical_bottom_15">
                <Button color="primary" className="float-right" onClick={this.handleCreateClick}>
                  Создать нового депутата
                </Button>
              </Col>
            </Row>
            <DeputiesTable
              data={deputies}
              districts={toSelectOptionsV2(districts, 'district', 'id')}
              towns={toSelectOptionsV2(towns, null, 'id')}
              areas={toSelectOptionsV2(areas, null, 'id')}
              areasO={areaToSelectOptionsV2(areas, towns, districts)}
              page={page}
              isLoading={isFetching}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default DeputiesList;
