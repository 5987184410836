// Officers list

const isFetchingOfficers = ({ officers }) => officers.isFetching;

const page = ({ officers }) => officers.page;

const sizePerPage = ({ officers }) => officers.sizePerPage;

const totalSize = ({ officers }) => officers.total;

const officersErrors = ({ officers }) => officers.errors;

const officers = ({ officers: v }) => v.items;

// Single officer

const isFetchingOfficer = ({ officer }) => officer.isFetching;

const officerErrors = ({ officer }) => officer.errors;

const officer = ({ officer: v }) => v;

export default {
  isFetchingOfficers,
  officers,
  page,
  totalSize,
  sizePerPage,
  officersErrors,
  isFetchingOfficer,
  officerErrors,
  officer,
};
