import React, { Component } from 'react';
import { get as _get, isEqual as _isEqual } from 'lodash';

import NotificationsService from '../../../../services/notifications.service';

const ComponentWithError = WrappedComponent => {
  return class extends Component {
    state = {
      formStatus: {},
      fieldsBeforeSubmission: {},
    };

    shouldComponentUpdate(nextProps) {
      const { errors } = nextProps;
      const { errors: prevErrors } = this.props;

      if (!errors.length && prevErrors.length) {
        this.setState({ formStatus: {} });
        return false;
      }

      if (errors.length && !_isEqual(errors, prevErrors)) {
        const formStatus = {};
        (Array.isArray(errors) ? errors : [errors]).forEach(error => {
          if (_get(error, ['field'], null)) formStatus[error.field] = error.message;
          else NotificationsService.addErrorNotification(error.message);
        });
        this.setState({ formStatus });
        return false;
      }

      return true;
    }

    saveFieldsState = fields => this.setState({ fieldsBeforeSubmission: fields });

    render() {
      const { formStatus, fieldsBeforeSubmission } = this.state;

      return <WrappedComponent {...this.props} errors={null} formStatus={formStatus} saveFieldsState={this.saveFieldsState} prevFieldsState={fieldsBeforeSubmission} />;
    }
  };
};

export default ComponentWithError;
