import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, Formik } from 'formik';

import ModalSuccess from '../../shared/modal/modal-success';
import PlainInput from '../../shared/inputs/plain';
import StyledDropzone from '../../shared/inputs/dropzone';

import validationSchema from './DeputyEdit.schema';

import { validate } from '../../../utils/form';
import { areaToSelectOptionsV2 } from '../../../utils/formatters';

class DeputyEdit extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    deputy: PropTypes.shape().isRequired,
    isFetching: PropTypes.bool.isRequired,
    prevFieldsState: PropTypes.shape().isRequired,
    formStatus: PropTypes.shape().isRequired,

    districts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    towns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    areas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    editDeputy: PropTypes.func.isRequired,
    removeDeputy: PropTypes.func.isRequired,
    removeDeputyPhoto: PropTypes.func.isRequired,
    saveFieldsState: PropTypes.func.isRequired,
    fetchDistricts: PropTypes.func.isRequired,
    fetchTowns: PropTypes.func.isRequired,
    fetchAreas: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    isOpenModalDeputy: false,
    isOpenModalPhoto: false,
  };

  componentDidMount() {
    const { districts, towns, areas, fetchDistricts, fetchTowns, fetchAreas } = this.props;

    if (!districts.length) fetchDistricts({ page: 1, limit: 1000 });
    if (!towns.length) fetchTowns({ page: 1, limit: 1000 });
    if (!areas.length) fetchAreas({ page: 1, limit: 1000 });
  }

  onSubmit = values => {
    const { saveFieldsState, editDeputy, match } = this.props;
    saveFieldsState(values);
    editDeputy({ id: match.params.id, data: values });
  };

  onRemoveDeputyAsk = () => {
    this.setState({
      isOpenModalDeputy: true,
    });
  };

  onModalDeputyClose = isOk => {
    this.setState({
      isOpenModalDeputy: false,
    });

    if (!isOk) return;

    const { removeDeputy, match } = this.props;
    removeDeputy({ id: match.params.id, response: this.onRemoveSuccess });
  };

  onRemoveSuccess = () => {
    const { history } = this.props;
    history.push(`/admin/deputies`);
  };

  onRemovePhotoAsk = () => {
    this.setState({
      isOpenModalPhoto: true,
    });
  };

  onModalPhotoClose = isOk => {
    this.setState({
      isOpenModalPhoto: false,
    });

    if (!isOk) return;

    const { removeDeputyPhoto, match } = this.props;
    removeDeputyPhoto({ id: match.params.id });
  };

  render() {
    const { districts, towns, areas, deputy, isFetching, prevFieldsState, formStatus } = this.props;
    const { isOpenModalDeputy, isOpenModalPhoto } = this.state;

    const areasO = areaToSelectOptionsV2(areas, towns, districts);

    const initialValues = {
      area: _get(areasO, deputy.id_address_area, ''),
      fio: deputy.fio || '',
      podr: deputy.podr || '',
      position: deputy.position || '',
      place: deputy.place || '',
      time: deputy.time || '',
      tel: deputy.tel || '',
      info: deputy.info || '',
      img: deputy.img ? `/assets/deputy/${deputy.img}` : '/assets/user-male-icon.png',
      imgFile: null,
    };

    return (
      <div className="animated fadeIn">
        <ModalSuccess
          isOpen={isOpenModalDeputy}
          className="modal-warning"
          strHeader="Удаление депутата"
          strBody="Вы уверены, что хотите удалить данного депутата?"
          onClose={() => this.onModalDeputyClose(false)}
          onClickOk={() => this.onModalDeputyClose(true)}
          onClickCancel={() => this.onModalDeputyClose(false)}
        />
        <ModalSuccess
          isOpen={isOpenModalPhoto}
          className="modal-warning"
          strHeader="Удаление фото"
          strBody="Вы уверены, что хотите удалить данное фото?"
          onClose={() => this.onModalPhotoClose(false)}
          onClickOk={() => this.onModalPhotoClose(true)}
          onClickCancel={() => this.onModalPhotoClose(false)}
        />
        <Formik
          initialValues={initialValues}
          isInitialValid
          enableReinitialize
          validate={validate(validationSchema)}
          onSubmit={this.onSubmit}
          render={({
            values,
            // errors,
            // touched,
            setFieldValue,
            // handleChange,
            // handleBlur,
            handleSubmit,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} noValidate name="deputyEditForm">
              <Row>
                <Col md={8}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>Депутат</strong>
                      <Button color="primary" className="float-right" onClick={this.onRemoveDeputyAsk}>
                        Удалить депутата
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-area">Район</Label>
                        <Field component={PlainInput} id="deputy-edit-area" name="area" value={values.area} disabled />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="deputy-edit-fio">ФИО</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.fio === values.fio ? formStatus.fio : ''}
                          id="deputy-edit-fio"
                          name="fio"
                          value={values.fio}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-podr">Структурное подразделение</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.podr === values.podr ? formStatus.podr : ''}
                          id="deputy-edit-podr"
                          name="podr"
                          value={values.podr}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-position">Должность</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.position === values.position ? formStatus.position : ''}
                          type="textarea"
                          rows="2"
                          id="deputy-edit-position"
                          name="position"
                          value={values.position}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-place">Место приема</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.place === values.place ? formStatus.place : ''}
                          id="deputy-edit-place"
                          name="place"
                          value={values.place}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-time">День недели; время приема;</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.time === values.time ? formStatus.time : ''}
                          id="deputy-edit-time"
                          name="time"
                          value={values.time}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-tel">Телефон</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.tel === values.tel ? formStatus.tel : ''}
                          id="deputy-edit-tel"
                          name="tel"
                          value={values.tel}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="deputy-edit-info">Дополнительная информация</Label>
                        <Field
                          component={PlainInput}
                          externalErrors={prevFieldsState.info === values.info ? formStatus.info : ''}
                          type="textarea"
                          rows="2"
                          id="deputy-edit-info"
                          name="info"
                          value={values.info}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Button type="submit" color="primary" className="mr-1" disabled={isFetching || !isValid}>
                          {isFetching ? 'Сохранение...' : 'Сохранить'}
                        </Button>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>Фото</strong>
                      <Button color="primary" className="float-right" onClick={this.onRemovePhotoAsk}>
                        Удалить фото
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Field
                          component={StyledDropzone}
                          id="deputy-edit-img"
                          name="img"
                          onChange={acceptedFiles => {
                            if (acceptedFiles.length > 0) {
                              if (values.imgFile) URL.revokeObjectURL(values.img);

                              setFieldValue('img', URL.createObjectURL(acceptedFiles[0]));
                              setFieldValue('imgFile', acceptedFiles[0]);
                            }
                          }}
                          classNameType="personal"
                          text1="Кликните или перетащите сюда фотографию"
                          text2="По возможности сделайте предобработку фотографии. Если Вы отправите большое фото, то это может затормозить загрузки у пользователя"
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default DeputyEdit;
