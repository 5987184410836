import { combineActions, createActions, handleActions } from 'redux-actions';

const {
  fetchOfficers,
  fetchOfficersSuccess,
  fetchOfficersFail,
  fetchOfficer,
  fetchOfficerSuccess,
  fetchOfficerFail,
  editOfficer,
  editOfficerSuccess,
  editOfficerFail,
  createOfficer,
  createOfficerSuccess,
  createOfficerFail,
  removeOfficer,
  removeOfficerSuccess,
  removeOfficerFail,
  removeOfficerPhoto,
  removeOfficerPhotoSuccess,
  removeOfficerPhotoFail,
} = createActions(
  'FETCH_OFFICERS',
  'FETCH_OFFICERS_SUCCESS',
  'FETCH_OFFICERS_FAIL',
  'FETCH_OFFICER',
  'FETCH_OFFICER_SUCCESS',
  'FETCH_OFFICER_FAIL',
  'EDIT_OFFICER',
  'EDIT_OFFICER_SUCCESS',
  'EDIT_OFFICER_FAIL',
  'CREATE_OFFICER',
  'CREATE_OFFICER_SUCCESS',
  'CREATE_OFFICER_FAIL',
  'REMOVE_OFFICER',
  'REMOVE_OFFICER_SUCCESS',
  'REMOVE_OFFICER_FAIL',
  'REMOVE_OFFICER_PHOTO',
  'REMOVE_OFFICER_PHOTO_SUCCESS',
  'REMOVE_OFFICER_PHOTO_FAIL'
);

const defaultState = {
  officers: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
  officer: {
    isFetching: false,
    isUpdating: false,
    errors: [],
  },
};

const officerReducer = handleActions(
  {
    [combineActions(fetchOfficer)]: state => ({
      ...state,
      ...defaultState.officer,
      isFetching: true,
    }),
    [combineActions(editOfficer, createOfficer, removeOfficer, removeOfficerPhoto)]: state => ({
      ...state,
      isUpdating: true,
    }),
    [combineActions(fetchOfficerSuccess, editOfficerSuccess, createOfficerSuccess, removeOfficerSuccess, removeOfficerPhotoSuccess)]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
      isUpdating: false,
    }),
    [combineActions(fetchOfficerFail, editOfficerFail, createOfficerFail, removeOfficerFail, removeOfficerPhotoFail)]: (state, action) => ({
      ...state,
      isFetching: false,
      isUpdating: false,
      errors: action.payload,
    }),
  },
  defaultState.officer
);

const officersReducer = handleActions(
  {
    [fetchOfficers]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchOfficersSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchOfficersFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.officers
);

export const reducer = { officer: officerReducer, officers: officersReducer };

export default {
  fetchOfficers,
  fetchOfficersSuccess,
  fetchOfficersFail,
  fetchOfficer,
  fetchOfficerSuccess,
  fetchOfficerFail,
  editOfficer,
  editOfficerSuccess,
  editOfficerFail,
  createOfficer,
  createOfficerSuccess,
  createOfficerFail,
  removeOfficer,
  removeOfficerSuccess,
  removeOfficerFail,
  removeOfficerPhoto,
  removeOfficerPhotoSuccess,
  removeOfficerPhotoFail,
};
