import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { authActions, authSelectors } from '../app/auth/duck';

import Router from './Router';

const mapStateToProps = state => ({
  user: authSelectors.user(state),
  role: authSelectors.role(state),
  isFetching: authSelectors.isFetching(state),
  loginTime: authSelectors.loginTime(state),
  maxLoginTime: authSelectors.maxLoginTime(state),
});

const mapDispatchToProps = {
  login: authActions.login,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Router)
);
