import fetch from 'cross-fetch';
import qs from 'qs';

import { serverErrorFormatter, toBase64 } from '../utils/formatters';

class FetchAPI {
  static jsonGETheaders(headers) {
    return {
      Accept: 'application/json',
      ...(process.env.REACT_APP_HTTP_AUTH_ENABLE === 1
        ? { Authorization: `Basic ${toBase64(`${process.env.REACT_APP_HTTP_AUTH_USER}:${process.env.REACT_APP_HTTP_AUTH_PASS}`)}` }
        : {}),
      ...headers,
    };
  }

  static jsonPOSTheaders(headers) {
    return {
      ...this.jsonGETheaders(),
      'Content-Type': 'application/json',
      ...headers,
    };
  }

  static buildUrl(path, queryStringOptions = {}) {
    const queryString = qs.stringify(queryStringOptions);
    // In development mode we're defining routes without host -
    // create-react-app automatically substitutes host, specified in 'proxy'
    // section in package.json
    // const apiHost = process.env.NODE_ENV === 'development' ?
    //   '' :
    //   process.env.REACT_APP_API_URL;
    const apiHost = process.env.REACT_APP_API_URL;

    return queryString ? `${apiHost}/${path}?${queryString}` : `${apiHost}/${path}`;
  }

  static responseHandler(response) {
    if (response.ok) {
      if (response.headers.get('content-type') === 'application/pdf')
        return response.blob().then(data => {
          return { response: data };
        });
      return response.json().then(data => ({ response: data }));
    }

    if (response.status === 401) {
      window.location = '/admin/logout';
    }

    if (response.status === 403) {
      window.location = '/admin/logout';
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then(json => ({
        errors: serverErrorFormatter(json.errors) || serverErrorFormatter(json),
        status: response.status,
      }));
    }
    return response.text().then(text => ({ errors: serverErrorFormatter(text), status: response.status }));
  }

  static errorHandler(err) {
    return { errors: serverErrorFormatter(err) };
  }

  static get(path, queryStringObject) {
    const { userCode, userIdArea } = window;

    let obj = userCode ? { ...queryStringObject, userCode } : queryStringObject;
    obj = userIdArea ? { ...obj, userIdArea } : obj;

    return fetch(this.buildUrl(path, obj), {
      headers: this.jsonGETheaders(),
      credentials: 'include',
    })
      .then(this.responseHandler)
      .catch(this.errorHandler);
  }

  static post(path, queryStringObject, body, headers) {
    return fetch(this.buildUrl(path, queryStringObject), {
      method: 'POST',
      headers: this.jsonPOSTheaders(headers),
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then(this.responseHandler)
      .catch(this.errorHandler);
  }

  static postFormData(path, queryStringObject, body, headers) {
    const bodyFD = new FormData();
    Object.keys(body).map(k => bodyFD.append(k, body[k]));

    const hd = this.jsonPOSTheaders(headers);
    delete hd['Content-Type'];

    return fetch(this.buildUrl(path, queryStringObject), {
      method: 'POST',
      headers: hd,
      body: bodyFD,
      credentials: 'include',
    })
      .then(this.responseHandler)
      .catch(this.errorHandler);
  }

  static put(path, queryStringObject, body) {
    return fetch(this.buildUrl(path, queryStringObject), {
      method: 'PUT',
      headers: this.jsonPOSTheaders(),
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then(this.responseHandler)
      .catch(this.errorHandler);
  }

  static del(path, queryStringObject, body) {
    return fetch(this.buildUrl(path, queryStringObject), {
      method: 'DELETE',
      headers: this.jsonPOSTheaders(),
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then(this.responseHandler)
      .catch(this.errorHandler);
  }
}

export default FetchAPI;
