import fetch from '../../../services/fetch-api.service';

const fetchOfficers = ({ filters, page, sortField, sortOrder, limit }) => fetch.get('admin/officers/list', { filters, page, sortField, sortOrder, limit });

const fetchOfficer = id => fetch.get(`admin/officer/view/${id}`);

const editOfficer = ({ id, data }) => fetch.postFormData(`admin/officer/edit/${id}`, null, data);

const createOfficer = data => fetch.postFormData('admin/officer/create', null, data);

const removeOfficer = id => fetch.get(`admin/officer/remove/${id}`);

const removeOfficerPhoto = id => fetch.get(`admin/officer/remove_photo/${id}`);

export default {
  fetchOfficers,
  fetchOfficer,
  editOfficer,
  createOfficer,
  removeOfficer,
  removeOfficerPhoto,
};
