import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Card, CardHeader, CardBody, Col, Form, FormGroup, Row, Label } from 'reactstrap';
import { Formik, Field } from 'formik';

import PlainInput from '../../shared/inputs/plain';
import StyledDropzone from '../../shared/inputs/dropzone';

import validationSchema from './UnitEdit.schema';

import { validate } from '../../../utils/form';

class UnitEdit extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    editUnit: PropTypes.func.isRequired,
    saveFieldsState: PropTypes.func.isRequired,
    unit: PropTypes.shape().isRequired,
    isFetching: PropTypes.bool.isRequired,
    prevFieldsState: PropTypes.shape().isRequired,
    formStatus: PropTypes.shape().isRequired,
  };

  static defaultProps = {};

  onSubmit = values => {
    const { saveFieldsState, editUnit, match } = this.props;
    saveFieldsState(values);
    editUnit({ id: match.params.id, data: values });
  };

  render() {
    const {
      unit: { fio, podr, position, place, time, tel, info, func, img },
      isFetching,
    } = this.props;
    const initialValues = {
      fio: fio || '',
      podr: podr || '',
      position: position || '',
      place: place || '',
      time: time || '',
      tel: tel || '',
      info: info || '',
      func: func || '',
      img: img ? `/assets/unit/${img}` : '/assets/user-male-icon.png',
      imgFile: null,
    };

    return (
      <div className="animated fadeIn">
        <Formik
          initialValues={initialValues}
          isInitialValid
          enableReinitialize
          validate={validate(validationSchema)}
          onSubmit={this.onSubmit}
          render={({
            values,
            // errors,
            // touched,
            // handleChange,
            setFieldValue,
            // handleBlur,
            handleSubmit,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} noValidate name="unitEditForm">
              <Row>
                <Col md={8}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>Руководитель</strong>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="officer-edit-fio">ФИО</Label>
                        <Field component={PlainInput} id="officer-edit-fio" name="fio" value={values.fio} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-podr">Структурное подразделение</Label>
                        <Field component={PlainInput} id="officer-edit-podr" name="podr" value={values.podr} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-position">Должность</Label>
                        <Field component={PlainInput} type="textarea" rows="2" id="officer-edit-position" name="position" value={values.position} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-place">Место приема</Label>
                        <Field component={PlainInput} id="officer-edit-place" name="place" value={values.place} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-time">День недели; время приема;</Label>
                        <Field component={PlainInput} id="officer-edit-time" name="time" value={values.time} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-tel">Телефон</Label>
                        <Field component={PlainInput} id="officer-edit-tel" name="tel" value={values.tel} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-info">Дополнительная информация</Label>
                        <Field component={PlainInput} type="textarea" rows="2" id="officer-edit-info" name="info" value={values.info} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="officer-edit-func">Функции</Label>
                        <Field component={PlainInput} type="textarea" rows="2" id="officer-edit-func" name="func" value={values.func} />
                      </FormGroup>

                      <FormGroup>
                        <Button type="submit" color="primary" className="mr-1" disabled={isFetching || !isValid}>
                          {isFetching ? 'Сохранение...' : 'Сохранить'}
                        </Button>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>Фото</strong>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Field
                          component={StyledDropzone}
                          id="officer-edit-img"
                          name="img"
                          onChange={acceptedFiles => {
                            if (acceptedFiles.length > 0) {
                              if (values.imgFile) URL.revokeObjectURL(values.img);

                              setFieldValue('img', URL.createObjectURL(acceptedFiles[0]));
                              setFieldValue('imgFile', acceptedFiles[0]);
                            }
                          }}
                          classNameType="personal"
                          text1="Кликните или перетащите сюда фотографию"
                          text2="По возможности сделайте предобработку фотографии. Если Вы отправите большое фото, то это может затормозить загрузки у пользователя"
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default UnitEdit;
