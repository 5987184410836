const isFetching = ({ auth }) => auth.isFetching;

const user = ({ auth }) => auth.user;

const role = ({ auth }) => auth.role;

const errors = ({ auth }) => auth.errors;

const loginTime = ({ auth }) => auth.loginTime;

const maxLoginTime = ({ auth }) => auth.maxLoginTime;

export default {
  isFetching,
  user,
  role,
  errors,
  loginTime,
  maxLoginTime,
};
