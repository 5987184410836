import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { officerActions, officerSelectors } from '../duck';

import OfficerEdit from './OfficerEdit';
import ComponentWithError from '../../shared/HOCs/componentWithError';
import WithLoader from '../../shared/HOCs/withLoader';
import { townActions, townSelectors } from '../../towns/duck';
import { areaActions, areaSelectors } from '../../areas/duck';
import { districtActions, districtSelectors } from '../../districts/duck';

const mapStateToProps = state => ({
  isFetching: officerSelectors.isFetchingOfficer(state),
  errors: officerSelectors.officerErrors(state),
  officer: officerSelectors.officer(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
});

const mapDispatchToProps = {
  fetch: officerActions.fetchOfficer,
  editOfficer: officerActions.editOfficer,
  removeOfficer: officerActions.removeOfficer,
  removeOfficerPhoto: officerActions.removeOfficerPhoto,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithLoader(ComponentWithError(OfficerEdit)))
);
