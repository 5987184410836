import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import procedureOperations from './operations';
import procedureActions from './modules';

import NotificationsService from '../../../services/notifications.service';

function* fetchProcedures({ payload }) {
  const { errors, response } = yield call(procedureOperations.fetchProcedures, payload);

  if (response) {
    const procedures = _get(response, 'data.procedures.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.procedures', {});
    yield put(procedureActions.fetchProceduresSuccess({ items: procedures, sizePerPage, page, total }));
  } else {
    yield put(procedureActions.fetchProceduresFail(errors));
  }
}

function* watchProceduresFetch() {
  yield takeLatest(procedureActions.fetchProcedures, fetchProcedures);
}

function* fetchProcedure({ payload: { id } }) {
  const { errors, response } = yield call(procedureOperations.fetchProcedure, id);
  const procedure = _get(response, 'data.procedure', null);

  if (response) {
    yield put(procedureActions.fetchProcedureSuccess({ procedure }));
  } else {
    yield put(procedureActions.fetchProcedureFail(errors));
  }
}

function* watchProcedureFetch() {
  yield takeLatest(procedureActions.fetchProcedure, fetchProcedure);
}

function* editProcedure({ payload }) {
  const { errors, response } = yield call(procedureOperations.editProcedure, payload);

  const procedure = _get(response, 'data.procedure', null);

  if (response) {
    NotificationsService.addSuccessNotification('Updated successfully');
    yield put(procedureActions.editProcedureSuccess({ procedure }));
  } else {
    yield put(procedureActions.editProcedureFail(errors));
  }
}

function* watchProcedureEdit() {
  yield takeLatest(procedureActions.editProcedure, editProcedure);
}

export default function* watchAll() {
  yield fork(watchProceduresFetch);
  yield fork(watchProcedureFetch);
  yield fork(watchProcedureEdit);
}
