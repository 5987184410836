import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import areaOperations from './operations';
import areaActions from './modules';

function* fetchAreas({ payload }) {
  const { errors, response } = yield call(areaOperations.fetchAreas, payload);

  if (response) {
    const areas = _get(response, 'data.areas.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.areas', {});
    yield put(areaActions.fetchAreasSuccess({ items: areas, sizePerPage, page, total }));
  } else {
    yield put(areaActions.fetchAreasFail(errors));
  }
}

function* watchAreasFetch() {
  yield takeLatest(areaActions.fetchAreas, fetchAreas);
}

export default function* watchAll() {
  yield fork(watchAreasFetch);
}
