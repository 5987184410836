import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { procedureActions, procedureSelectors } from '../duck';

import ProcedureEdit from './ProcedureEdit';
import ComponentWithError from '../../shared/HOCs/componentWithError';
import WithLoader from '../../shared/HOCs/withLoader';

const mapStateToProps = state => ({
  procedure: procedureSelectors.procedure(state),
  errors: procedureSelectors.procedureErrors(state),
  isFetching: procedureSelectors.isFetchingProcedure(state),
});

const mapDispatchToProps = {
  fetch: procedureActions.fetchProcedure,
  editProcedure: procedureActions.editProcedure,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithLoader(ComponentWithError(ProcedureEdit)))
);
