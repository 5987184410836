// Procedures list

const isFetchingProcedures = ({ procedures }) => procedures.isFetching;

const page = ({ procedures }) => procedures.page;

const sizePerPage = ({ procedures }) => procedures.sizePerPage;

const totalSize = ({ procedures }) => procedures.total;

const proceduresErrors = ({ procedures }) => procedures.errors;

const procedures = ({ procedures: v }) => v.items;

// Single procedure

const isFetchingProcedure = ({ procedure }) => procedure.isFetching;

const procedureErrors = ({ procedure }) => procedure.errors;

const procedure = ({ procedure: v }) => v;

export default {
  isFetchingProcedures,
  procedures,
  page,
  totalSize,
  sizePerPage,
  proceduresErrors,
  isFetchingProcedure,
  procedureErrors,
  procedure,
};
