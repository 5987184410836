import { combineActions, createActions, handleActions } from 'redux-actions';

const { fetchUnits, fetchUnitsSuccess, fetchUnitsFail, fetchUnit, fetchUnitSuccess, fetchUnitFail, editUnit, editUnitSuccess, editUnitFail } = createActions(
  'FETCH_UNITS',
  'FETCH_UNITS_SUCCESS',
  'FETCH_UNITS_FAIL',
  'FETCH_UNIT',
  'FETCH_UNIT_SUCCESS',
  'FETCH_UNIT_FAIL',
  'EDIT_UNIT',
  'EDIT_UNIT_SUCCESS',
  'EDIT_UNIT_FAIL'
);

const defaultState = {
  units: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
  unit: {
    isFetching: false,
    errors: [],
  },
};

const unitReducer = handleActions(
  {
    [combineActions(fetchUnit, editUnit)]: state => ({
      ...state,
      isFetching: true,
    }),
    [combineActions(fetchUnitSuccess, editUnitSuccess)]: (state, action) => ({
      ...state,
      ...action.payload.unit,
      errors: [],
      isFetching: false,
    }),
    [combineActions(fetchUnitFail, editUnitFail)]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.unit
);

const unitsReducer = handleActions(
  {
    [fetchUnits]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchUnitsSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchUnitsFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.units
);

export const reducer = { unit: unitReducer, units: unitsReducer };

export default {
  fetchUnits,
  fetchUnitsSuccess,
  fetchUnitsFail,
  fetchUnit,
  fetchUnitSuccess,
  fetchUnitFail,
  editUnit,
  editUnitSuccess,
  editUnitFail,
};
