import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { get as _get } from 'lodash';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { Field, Formik } from 'formik';

import EmailInput from '../../shared/inputs/email';
import PasswordInput from '../../shared/inputs/password';

import validationSchema from './Login.schema';

import { validate } from '../../../utils/form';

class Login extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    user: PropTypes.shape(),
    login: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    errors: [],
    user: {},
  };

  state = {
    email: '',
    password: '',
    errors: [],
  };

  handleSubmit = values => {
    const { login } = this.props;
    login(values);
  };

  handleStateChange = field => e => {
    this.setState({
      [field]: e.target.value,
    });
  };

  render() {
    const { isFetching, user, formStatus, prevFieldsState, location } = this.props;
    const to = _get(location, 'state.from', { pathname: '/' });
    const initialValues = {
      email: '',
      password: '',
    };

    return user ? (
      <Redirect to={to} />
    ) : (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="p-4">
                <CardBody>
                  <Formik
                    validate={validate(validationSchema)}
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    render={({ values, handleChange, handleBlur, handleSubmit, isValid }) => (
                      <Form onSubmit={handleSubmit} noValidate name="managerCreateForm">
                        <h1>Login</h1>
                        <p className="text-muted">Sign In to your account</p>
                        <FormGroup>
                          <Field
                            component={EmailInput}
                            externalErrors={prevFieldsState.email === values.email ? formStatus.email : ''}
                            id="login-email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Field
                            component={PasswordInput}
                            externalErrors={prevFieldsState.password === values.password ? formStatus.password : ''}
                            id="login-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Button type="submit" color="primary" className="mr-1" disabled={isFetching || !isValid}>
                            {isFetching ? 'Logging in...' : 'Login'}
                          </Button>
                        </FormGroup>
                      </Form>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
