import { createActions, handleActions, combineActions } from 'redux-actions';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const { login, loginSuccess, loginFail, logout, logoutSuccess, logoutFail } = createActions(
  'LOGIN',
  'LOGIN_SUCCESS',
  'LOGIN_FAIL',
  'LOGOUT',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAIL'
);

const defaultState = {
  isFetching: false,
  user: null,
  role: null,
  loginTime: 0,
  maxLoginTime: 28800000, // maybe add to config?
  errors: [],
};

const authReducer = handleActions(
  {
    [combineActions(login, logout)]: state => ({
      ...state,
      user: null,
      role: null,
      loginTime: 0,
      isFetching: true,
    }),
    [loginSuccess]: (state, action) => ({
      ...state,
      isFetching: false,
      user: action.payload.user,
      role: action.payload.role,
      loginTime: Date.now(),
      errors: [],
    }),
    [logoutSuccess]: state => ({
      ...state,
      isFetching: false,
      user: null,
      role: null,
      loginTime: 0,
      errors: [],
    }),
    // Logging user out even in case of error, suppressing any errors
    [combineActions(loginFail, logoutFail)]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState
);

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'role'],
};

export const reducer = persistReducer(persistConfig, authReducer);

export default {
  login,
  loginFail,
  loginSuccess,
  logout,
  logoutSuccess,
  logoutFail,
};
