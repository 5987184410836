import Select from 'react-select';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { get as _get } from 'lodash';

import { getSelectOption } from '../../../../utils/formatters';
import { getErrorDisplay } from '../utils';

const SelectInput = ({ externalErrors, options, field, form: { errors, setFieldValue }, ...props }) => (
  <Fragment>
    <Select
      {...field}
      {...props}
      value={getSelectOption(options, field.value)}
      defaultValue={getSelectOption(options, field.value)}
      options={options}
      onChange={option => setFieldValue(field.name, _get(option, 'value', null))}
    />
    {getErrorDisplay(errors[field.name])}
    {getErrorDisplay(externalErrors)}
  </Fragment>
);

SelectInput.propTypes = {
  field: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({}).isRequired,
  externalErrors: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SelectInput.defaultProps = {
  externalErrors: '',
  required: false,
  placeholder: '',
};

export default SelectInput;
