import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import deputyOperations from './operations';
import deputyActions from './modules';

import NotificationsService from '../../../services/notifications.service';

function* fetchDeputies({ payload }) {
  const { errors, response } = yield call(deputyOperations.fetchDeputies, payload);

  if (response) {
    const deputies = _get(response, 'data.deputies.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.deputies', {});
    yield put(deputyActions.fetchDeputiesSuccess({ items: deputies, sizePerPage, page, total }));
  } else {
    yield put(deputyActions.fetchDeputiesFail(errors));
  }
}

function* watchDeputiesFetch() {
  yield takeLatest(deputyActions.fetchDeputies, fetchDeputies);
}

function* fetchDeputy({ payload: { id } }) {
  const { errors, response } = yield call(deputyOperations.fetchDeputy, id);

  if (response) {
    const deputy = _get(response, 'data.deputy', null);
    yield put(deputyActions.fetchDeputySuccess(deputy));
  } else {
    yield put(deputyActions.fetchDeputyFail(errors));
  }
}

function* watchDeputyFetch() {
  yield takeLatest(deputyActions.fetchDeputy, fetchDeputy);
}

function* editDeputy({ payload }) {
  const { errors, response } = yield call(deputyOperations.editDeputy, payload);

  const deputy = _get(response, 'data.deputy', null);

  if (response) {
    NotificationsService.addSuccessNotification('Updated successfully');
    yield put(deputyActions.editDeputySuccess(deputy));
  } else {
    yield put(deputyActions.editDeputyFail(errors));
  }
}

function* watchDeputyEdit() {
  yield takeLatest(deputyActions.editDeputy, editDeputy);
}

function* createDeputy({ payload }) {
  const { errors, response } = yield call(deputyOperations.createDeputy, payload.data);

  const deputy = _get(response, 'data.deputy', null);

  if (response) {
    NotificationsService.addSuccessNotification('Create successfully');
    yield put(deputyActions.createDeputySuccess(deputy));
    payload.response(deputy);
  } else {
    yield put(deputyActions.createDeputyFail(errors));
  }
}

function* watchDeputyCreate() {
  yield takeLatest(deputyActions.createDeputy, createDeputy);
}

function* removeDeputy({ payload }) {
  const { errors, response } = yield call(deputyOperations.removeDeputy, payload.id);

  if (response) {
    NotificationsService.addSuccessNotification('Remove successfully');
    yield put(deputyActions.removeDeputySuccess({}));
    payload.response();
  } else {
    yield put(deputyActions.removeDeputyFail(errors));
  }
}

function* watchDeputyRemove() {
  yield takeLatest(deputyActions.removeDeputy, removeDeputy);
}

function* removeDeputyPhoto({ payload: { id } }) {
  const { errors, response } = yield call(deputyOperations.removeDeputyPhoto, id);

  if (response) {
    NotificationsService.addSuccessNotification('Remove successfully');
    const deputy = _get(response, 'data.deputy', null);
    yield put(deputyActions.removeDeputyPhotoSuccess(deputy));
  } else {
    yield put(deputyActions.removeDeputyPhotoFail(errors));
  }
}

function* watchDeputyPhotoRemove() {
  yield takeLatest(deputyActions.removeDeputyPhoto, removeDeputyPhoto);
}

export default function* watchAll() {
  yield fork(watchDeputiesFetch);
  yield fork(watchDeputyFetch);
  yield fork(watchDeputyEdit);
  yield fork(watchDeputyCreate);
  yield fork(watchDeputyRemove);
  yield fork(watchDeputyPhotoRemove);
}
