import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { unitActions, unitSelectors } from '../duck';

import UnitEdit from './UnitEdit';
import ComponentWithError from '../../shared/HOCs/componentWithError';
import WithLoader from '../../shared/HOCs/withLoader';

const mapStateToProps = state => ({
  unit: unitSelectors.unit(state),
  errors: unitSelectors.unitErrors(state),
  isFetching: unitSelectors.isFetchingUnit(state),
});

const mapDispatchToProps = {
  fetch: unitActions.fetchUnit,
  editUnit: unitActions.editUnit,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithLoader(ComponentWithError(UnitEdit)))
);
