import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { Link } from 'react-router-dom';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import Loader from '../../../shared/loader';

class DeputiesTable extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    districts: PropTypes.shape({}).isRequired,
    towns: PropTypes.shape({}).isRequired,
    areas: PropTypes.shape({}).isRequired,
    areasO: PropTypes.shape({}).isRequired,
    onTableChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };
  // id: 553
  // id_address_area: 120
  // id_district: 8
  // order_num: 1

  formatterId = (fio, row) => <Link to={`/admin/deputy/${row.id}`}>{fio || row.id}</Link>;

  formatterImg = img => (
    <img
      alt=""
      style={{ width: '100%' }}
      src={img ? `${process.env.REACT_APP_API_URL}/assets/deputy/${img}` : `${process.env.REACT_APP_API_URL}/assets/user-male-icon.png`}
    />
  );

  formatterIdAddressArea = idAddressArea => {
    const { districts, areas, towns } = this.props;
    // const districtName = _get(districts, [idDistrict], '');
    // let area = _get(areas, [row.id_address_area], {});
    // let town = {};
    // if (!Object.keys(area).length) {
    //   Object.keys(towns).forEach(key => {
    //     if (towns[key].id_district === idDistrict) town = towns[key];
    //   });
    //
    //   Object.keys(areas).forEach(key => {
    //     if (areas[key].id_town === town.id) area = areas[key];
    //   });
    // }

    const idTown = _get(areas, [idAddressArea, 'id_town'], -1);
    const idDistrict = _get(towns, [idTown, 'id_district'], -1);
    const districtName = _get(districts, [idDistrict], '');
    return (
      <div>
        {districtName}
        <hr />
        {_get(areas, [idAddressArea, 'area'], '')}
      </div>
    );
  };

  render() {
    const { onTableChange, data, page, sizePerPage, totalSize, isLoading, districts, towns, areas, areasO } = this.props;

    if (!Object.keys(districts).length || !Object.keys(towns).length || !Object.keys(areas).length) return '';

    this.columns = [
      { dataField: 'id', hidden: true },
      {
        dataField: 'id_address_area',
        text: 'Район',
        formatter: this.formatterIdAddressArea,
        filter: selectFilter({
          options: areasO,
        }),
      },
      { dataField: 'fio', text: 'ФИО', sort: true, filter: textFilter(), formatter: this.formatterId },
      { dataField: 'podr', text: 'Структурное подразделение', sort: true, filter: textFilter() },
      { dataField: 'position', text: 'Должность', filter: textFilter() },
      { dataField: 'place', text: 'Место приема', filter: textFilter() },
      { dataField: 'time', text: 'День недели; время приема;', filter: textFilter() },
      { dataField: 'tel', text: 'Телефон', filter: textFilter() },
      { dataField: 'info', text: 'Дополнительная информация', filter: textFilter() },
      { dataField: 'img', text: 'фото', formatter: this.formatterImg },
    ];

    return (
      <BootstrapTable
        remote
        bootstrap4
        striped
        keyField="id"
        data={data}
        columns={this.columns}
        onTableChange={onTableChange}
        noDataIndication={() => isLoading && <Loader />}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        filter={filterFactory()}
      />
    );
  }
}

export default DeputiesTable;
