import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import './AccountDropdown.scss';

class AccountDropdown extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  state = {
    dropdownOpen: false,
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    const {
      onLogout,
      user: { email },
    } = this.props;

    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <span className="account-dropdown__toggle">
            <span>{email}</span>
            <i className="cui-chevron-bottom" aria-hidden="true" />
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={onLogout}>
            <i className="fa fa-lock" />
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default AccountDropdown;
