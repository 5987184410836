import fetch from '../../../services/fetch-api.service';

const fetchDeputies = ({ filters, page, sortField, sortOrder, limit }) => fetch.get('admin/deputies/list', { filters, page, sortField, sortOrder, limit });

const fetchDeputy = id => fetch.get(`admin/deputy/view/${id}`);

const editDeputy = ({ id, data }) => fetch.postFormData(`admin/deputy/edit/${id}`, null, data);

const createDeputy = data => fetch.postFormData('admin/deputy/create', null, data);

const removeDeputy = id => fetch.get(`admin/deputy/remove/${id}`);

const removeDeputyPhoto = id => fetch.get(`admin/deputy/remove_photo/${id}`);

export default {
  fetchDeputies,
  fetchDeputy,
  editDeputy,
  createDeputy,
  removeDeputy,
  removeDeputyPhoto,
};
