import { connect } from 'react-redux';

import { unitSelectors, unitActions } from '../duck';
import { districtSelectors, districtActions } from '../../districts/duck';
import { townSelectors, townActions } from '../../towns/duck';
import { areaSelectors, areaActions } from '../../areas/duck';
import { sikSelectors, sikActions } from '../../siks/duck';

import UnitsList from './UnitsList';
import ComponentWithError from '../../shared/HOCs/componentWithError';

const mapStateToProps = state => ({
  isFetching: unitSelectors.isFetchingUnits(state),
  page: unitSelectors.page(state),
  sizePerPage: unitSelectors.sizePerPage(state),
  totalSize: unitSelectors.totalSize(state),
  errors: unitSelectors.unitsErrors(state),

  units: unitSelectors.units(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
  siks: sikSelectors.siks(state),
});

const mapDispatchToProps = {
  fetchUnits: unitActions.fetchUnits,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
  fetchSiks: sikActions.fetchSiks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithError(UnitsList));
