import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import townOperations from './operations';
import townActions from './modules';

function* fetchTowns({ payload }) {
  const { errors, response } = yield call(townOperations.fetchTowns, payload);

  if (response) {
    const towns = _get(response, 'data.towns.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.towns', {});
    yield put(townActions.fetchTownsSuccess({ items: towns, sizePerPage, page, total }));
  } else {
    yield put(townActions.fetchTownsFail(errors));
  }
}

function* watchTownsFetch() {
  yield takeLatest(townActions.fetchTowns, fetchTowns);
}

export default function* watchAll() {
  yield fork(watchTownsFetch);
}
