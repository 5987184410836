import { createActions, handleActions } from 'redux-actions';

const { fetchTowns, fetchTownsSuccess, fetchTownsFail } = createActions('FETCH_TOWNS', 'FETCH_TOWNS_SUCCESS', 'FETCH_TOWNS_FAIL');

const defaultState = {
  towns: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
};

const townsReducer = handleActions(
  {
    [fetchTowns]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchTownsSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchTownsFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.towns
);

export const reducer = { towns: townsReducer };

export default {
  fetchTowns,
  fetchTownsSuccess,
  fetchTownsFail,
};
