import { put, call, fork, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import authOperations from './operations';
import authActions from './modules';

function* login({ payload: { email, password } }) {
  // const { errors, response } = yield call(authOperations.login, { email, password });

  const errors = [{ message: 'Invalid email or password.' }];
  let response = null;

  if (email === 'root' && password === 'tiy165') {
    response = { data: { role: 'admin', admin: { email: 'Администратор', code: null } } };
  }

  if (email === 'gomel_town_isp' && password === 'bsnxnc') {
    response = { data: { role: 'admin', admin: { email: 'Гомельский городской исполнительный комитет', code: '8', id_area: '118' } } };
  }
  if (email === 'gomel_central' && password === 'asf1to') {
    response = { data: { role: 'admin', admin: { email: 'Администрация Центрального района', code: '8', id_area: '119' } } };
  }
  if (email === 'gomel_zhelez' && password === 'o0829n') {
    response = { data: { role: 'admin', admin: { email: 'Администрация Железнодорожного района', code: '8', id_area: '120' } } };
  }
  if (email === 'gomel_sovetsky' && password === '844u6m') {
    response = { data: { role: 'admin', admin: { email: 'Администрация Советского района', code: '8', id_area: '121' } } };
  }
  if (email === 'gomel_regional_isp' && password === 'tazgrr') {
    response = { data: { role: 'admin', admin: { email: 'Гомельский областной исполнительный комитет', code: '8', id_area: '134' } } };
  }
  if (email === 'gomel_district_isp' && password === 'ilig7r') {
    response = { data: { role: 'admin', admin: { email: 'Гомельский районный исполнительный комитет', code: '8', id_area: '135' } } };
  }
  if (email === 'gomel_novobel' && password === 'g38ktx') {
    response = { data: { role: 'admin', admin: { email: 'Администрация Новобелицкого района', code: '8', id_area: '136' } } };
  }

  if (email === 'bragin' && password === 'b8l6xu') {
    response = { data: { role: 'admin', admin: { email: 'Брагин', code: '5' } } };
  }
  if (email === 'buda-koshelevo' && password === 'gcqwo6') {
    response = { data: { role: 'admin', admin: { email: 'Буда-Кошелево', code: '6' } } };
  }
  if (email === 'vetka' && password === '3gpv9q') {
    response = { data: { role: 'admin', admin: { email: 'Ветка', code: '7' } } };
  }
  if (email === 'dobrush' && password === 'htsw0z') {
    response = { data: { role: 'admin', admin: { email: 'Добруш', code: '9' } } };
  }
  if (email === 'yelsk' && password === 'sb5ib1') {
    response = { data: { role: 'admin', admin: { email: 'Ельск', code: '10' } } };
  }
  if (email === 'zhitkovichi' && password === 'tmlala') {
    response = { data: { role: 'admin', admin: { email: 'Житковичи', code: '11' } } };
  }
  if (email === 'zhlobin' && password === 'laa39k') {
    response = { data: { role: 'admin', admin: { email: 'Жлобин', code: '12' } } };
  }
  if (email === 'kalinkovichi' && password === 'x1ulpv') {
    response = { data: { role: 'admin', admin: { email: 'Калинковичи', code: '13' } } };
  }
  if (email === 'korma' && password === 'x4oapt') {
    response = { data: { role: 'admin', admin: { email: 'Корма', code: '14' } } };
  }
  if (email === 'lelchitsy' && password === '48nmpz') {
    response = { data: { role: 'admin', admin: { email: 'Лельчицы', code: '15' } } };
  }
  if (email === 'loev' && password === 'w4j4wv') {
    response = { data: { role: 'admin', admin: { email: 'Лоев', code: '16' } } };
  }
  if (email === 'mozyr' && password === 'bkqv52') {
    response = { data: { role: 'admin', admin: { email: 'Мозырь', code: '17' } } };
  }
  if (email === 'narovlya' && password === '9g6i9a') {
    response = { data: { role: 'admin', admin: { email: 'Наровля', code: '18' } } };
  }
  if (email === 'oktyabrskiy' && password === '3b37j7') {
    response = { data: { role: 'admin', admin: { email: 'Октябрьский', code: '19' } } };
  }
  if (email === 'petrikov' && password === 'cayxxw') {
    response = { data: { role: 'admin', admin: { email: 'Петриков', code: '20' } } };
  }
  if (email === 'rechitsa' && password === 'gcswky') {
    response = { data: { role: 'admin', admin: { email: 'Речица', code: '21' } } };
  }
  if (email === 'rogachev' && password === '79t0fz') {
    response = { data: { role: 'admin', admin: { email: 'Рогачёв', code: '22' } } };
  }
  if (email === 'svetlogorsk' && password === 'dawe3e') {
    response = { data: { role: 'admin', admin: { email: 'Светлогорск', code: '23' } } };
  }
  if (email === 'khoiniki' && password === 'voei98') {
    response = { data: { role: 'admin', admin: { email: 'Хойники', code: '24' } } };
  }
  if (email === 'chechersk' && password === '1l9ta2') {
    response = { data: { role: 'admin', admin: { email: 'Чечерск', code: '25' } } };
  }

  if (response) {
    const role = _get(response, 'data.role', null);
    const user = _get(response, `data.${role}`, null);

    yield put(authActions.loginSuccess({ user, role }));
  } else {
    yield put(authActions.loginFail(errors));
  }
}

function* watchLogin() {
  yield takeLatest(authActions.login, login);
}

function* logout() {
  // const {errors, response} = yield call(authOperations.logout);

  // if (response) {
  yield put(authActions.logoutSuccess());
  // } else {
  //   yield put(authActions.logoutFail(errors));
  // }
}

function* watchLogout() {
  yield takeLatest(authActions.logout, logout);
}

export default function* watchAll() {
  yield fork(watchLogin);
  yield fork(watchLogout);
}
