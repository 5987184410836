import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../loader';

const WithLoader = WrappedComponent => {
  /*
    As this component displays loader depending on the 'isFetching' prop
    state, ti use this component, the following rules must be complied:
    1) the prop, responsible for the loader state, must be named as 'isFetching'
    2) the data fetching method must be named as 'fetch'
    3) as we're displaying loader depending on the 'isFetching' prop state,
    DON'T use any method in WrappedComponent's constructor/componentDidMount/ComponentDidUpdate
    that modifies 'isFetching' state - this will cause infinite recursion!
  */

  return class extends Component {
    componentDidMount() {
      const { fetch } = this.props;
      fetch(this.buildQueryObject());
    }

    // As we're defining initial fetching Logic in Loader component,
    // we can't know in advance, what exactly parameters the 'fetch'
    // method will be needed. So we're trying to extract all possible appropriate
    // fields from props and building queryObject, which will be passed to 'fetch' method.
    buildQueryObject() {
      const { id, match, page, limit, filters, sortBy, total } = this.props;
      const _id = id || match.params.id;
      const queryObject = {};

      if (_id) {
        queryObject.id = _id;
      }
      if (limit) {
        queryObject.limit = limit;
      }
      if (filters) {
        queryObject.filters = filters;
      }
      if (total) {
        queryObject.total = total;
      }
      if (sortBy) {
        queryObject.sortBy = sortBy;
      }
      if (page) {
        queryObject.page = page;
      }

      return queryObject;
    }

    render() {
      const { isFetching } = this.props;
      if (isFetching) {
        return <Loader />;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
};

WithLoader.propTypes = {
  fetch: PropTypes.func.isRequired,
};

export default WithLoader;
