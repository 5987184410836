// Deputies list

const isFetchingDeputies = ({ deputies }) => deputies.isFetching;

const page = ({ deputies }) => deputies.page;

const sizePerPage = ({ deputies }) => deputies.sizePerPage;

const totalSize = ({ deputies }) => deputies.total;

const deputiesErrors = ({ deputies }) => deputies.errors;

const deputies = ({ deputies: v }) => v.items;

// Single deputy

const isFetchingDeputy = ({ deputy }) => deputy.isFetching;

const deputyErrors = ({ deputy }) => deputy.errors;

const deputy = ({ deputy: v }) => v;

export default {
  isFetchingDeputies,
  deputies,
  page,
  totalSize,
  sizePerPage,
  deputiesErrors,
  isFetchingDeputy,
  deputyErrors,
  deputy,
};
