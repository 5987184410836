import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

import Can from '../permissions/can';
import fallbackRoutes from '../permissions/fallbackRoutes';

import Layout from '../app/layout';
import Login from '../app/auth/Login';
import Logout from '../app/auth/Logout';

import OfficersList from '../app/officers/list';
import OfficerEdit from '../app/officers/edit';
import OfficerCreate from '../app/officers/create';

import DeputiesList from '../app/deputies/list';
import DeputyEdit from '../app/deputies/edit';
import DeputyCreate from '../app/deputies/create';

import ProceduresList from '../app/procedures/list';
import ProcedureEdit from '../app/procedures/edit';

import UnitsList from '../app/units/list';
import UnitEdit from '../app/units/edit';

import NotFound from '../app/404';

const PrivateRoute = ({ authData: { user, role }, permission, ...props }) => {
  if (user) {
    window.userCode = _get(user, `code`, null);
    window.userIdArea = _get(user, `id_area`, null);

    return permission ? (
      <Can permission={permission} role={role} yes={() => <Route {...props} />} no={() => <Redirect to={fallbackRoutes[role]} />} />
    ) : (
      <Route {...props} />
    );
  }

  const { location } = props;
  return <Redirect to={{ pathname: '/admin/auth', state: { from: location } }} />;
};

const DashboardLayoutRoute = ({ component: ComponentCh, isPrivate, ...rest }) => {
  const RouteComponent = isPrivate ? PrivateRoute : Route;
  return (
    <RouteComponent
      {...rest}
      render={matchProps => (
        <Layout>
          <ComponentCh {...matchProps} />
        </Layout>
      )}
    />
  );
};

const Router = ({ user, role, isFetching, loginTime, maxLoginTime, login }) => {
  const authData = { user, role, isFetching };

  return (
    <Switch>
      <Redirect exact from="/" to="/admin" />
      <Redirect exact from="/admin" to="/admin/officers" />
      <Route exact path="/admin/auth" component={Login} />
      <Route exact path="/admin/logout" component={Logout} />

      <DashboardLayoutRoute authData={authData} permission="officers.view" isPrivate exact path="/admin/officers" component={OfficersList} />
      <DashboardLayoutRoute authData={authData} permission="officer.create" isPrivate exact path="/admin/officer/create" component={OfficerCreate} />
      <DashboardLayoutRoute authData={authData} permission="officer.edit" isPrivate exact path="/admin/officer/:id" component={OfficerEdit} />

      <DashboardLayoutRoute authData={authData} permission="deputies.view" isPrivate exact path="/admin/deputies" component={DeputiesList} />
      <DashboardLayoutRoute authData={authData} permission="deputy.create" isPrivate exact path="/admin/deputy/create" component={DeputyCreate} />
      <DashboardLayoutRoute authData={authData} permission="deputy.edit" isPrivate exact path="/admin/deputy/:id" component={DeputyEdit} />

      <DashboardLayoutRoute authData={authData} permission="procedures.view" isPrivate exact path="/admin/procedures" component={ProceduresList} />
      <DashboardLayoutRoute authData={authData} permission="procedure.edit" isPrivate exact path="/admin/procedure/:id" component={ProcedureEdit} />

      <DashboardLayoutRoute authData={authData} permission="units.view" isPrivate exact path="/admin/units" component={UnitsList} />
      <DashboardLayoutRoute authData={authData} permission="unit.edit" isPrivate exact path="/admin/unit/:id" component={UnitEdit} />

      <Route component={NotFound} />
    </Switch>
  );
};

Router.propTypes = {
  user: PropTypes.shape().isRequired,
  role: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,

  login: PropTypes.func.isRequired,
};

Router.defaultProps = {};

export default Router;
