import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Card, CardHeader, CardBody, Col, Form, FormGroup, Row, Label } from 'reactstrap';
import { Formik, Field } from 'formik';

import PlainInput from '../../shared/inputs/plain';
import StyledDropzone from '../../shared/inputs/dropzone';

import validationSchema from './ProcedureEdit.schema';

import { validate } from '../../../utils/form';

class ProcedureEdit extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    editProcedure: PropTypes.func.isRequired,
    saveFieldsState: PropTypes.func.isRequired,
    procedure: PropTypes.shape().isRequired,
    isFetching: PropTypes.bool.isRequired,
    prevFieldsState: PropTypes.shape().isRequired,
    formStatus: PropTypes.shape().isRequired,
  };

  static defaultProps = {};

  onSubmit = values => {
    const { saveFieldsState, editProcedure, match } = this.props;
    saveFieldsState(values);
    editProcedure({ id: match.params.id, data: values });
  };

  render() {
    const {
      procedure: { ap_document, ap_money, ap_organ, ap_time, ap_valid, data, code, number },
      isFetching,
    } = this.props;
    const initialValues = {
      ap_document: ap_document || '',
      ap_money: ap_money || '',
      ap_organ: ap_organ || '',
      ap_time: ap_time || '',
      ap_valid: ap_valid || '',
      data: data || '',
      code: code || '',
      number: number || '',
    };

    return (
      <div className="animated fadeIn">
        <Formik
          initialValues={initialValues}
          isInitialValid
          enableReinitialize
          validate={validate(validationSchema)}
          onSubmit={this.onSubmit}
          render={({
            values,
            // errors,
            // touched,
            // handleChange,
            setFieldValue,
            // handleBlur,
            handleSubmit,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} noValidate name="procedureEditForm">
              <Row>
                <Col md={8}>
                  <Card>
                    <CardHeader>
                      <i className="icon-note" />
                      <strong>АП</strong>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label htmlFor="procedure-edit-data">Наименование</Label>
                        <Field component={PlainInput} id="procedure-edit-data" name="data" value={values.data} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="procedure-edit-ap_organ">Государственный орган (иная организация), в который гражданин должен обратиться</Label>
                        <Field component={PlainInput} id="procedure-edit-ap_organ" name="ap_organ" value={values.ap_organ} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="procedure-edit-ap_document">Документы и (или) сведения, представляемые гражданином для осуществления административной процедуры</Label>
                        <Field component={PlainInput} type="textarea" rows="4" id="procedure-edit-ap_document" name="ap_document" value={values.ap_document} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="procedure-edit-ap_money">Размер платы, взимаемой при осуществлении административной процедуры</Label>
                        <Field component={PlainInput} id="procedure-edit-ap_money" name="ap_money" value={values.ap_money} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="procedure-edit-ap_time">Максимальный срок осуществления административной процедуры</Label>
                        <Field component={PlainInput} id="procedure-edit-ap_time" name="ap_time" value={values.ap_time} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="procedure-edit-ap_valid">Срок действия справки, другого документа (решения), выдаваемых (принимаемого) при осуществлении административной процедуры</Label>
                        <Field component={PlainInput} id="procedure-edit-ap_valid" name="ap_valid" value={values.ap_valid} />
                      </FormGroup>

                      <FormGroup>
                        <Button type="submit" color="primary" className="mr-1" disabled={isFetching || !isValid}>
                          {isFetching ? 'Сохранение...' : 'Сохранить'}
                        </Button>
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default ProcedureEdit;
