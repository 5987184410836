export default {
  items: [
    {
      name: 'Депутаты',
      url: '/admin/deputies',
      icon: 'cui-people',
      permission: 'deputies.view',
    },
    {
      name: 'Руководители',
      url: '/admin/officers',
      icon: 'cui-people',
      permission: 'officers.view',
    },
    {
      name: 'Административные процедуры',
      url: '/admin/procedures',
      icon: 'cui-people',
      permission: 'procedures.view',
    },
    {
      name: 'Подразделения',
      url: '/admin/units',
      icon: 'cui-people',
      permission: 'units.view',
    },
    // {
    //   name: 'Managers',
    //   url: '/admin/managers',
    //   icon: 'cui-people',
    //   permission: 'managers.view',
    // },
    // {
    //   name: 'Clients',
    //   url: '/admin/clients',
    //   icon: 'fa fa-users',
    //   permission: 'clients.view',
    // },
    // {
    //   name: 'Templates',
    //   url: '/admin/clients',
    //   //url: '/admin/templates',
    //   icon: 'cui-note',
    //   permission: 'templates',
    //   // children: [
    //   //   {
    //   //     name: 'Document',
    //   //     url: '/admin/clients',
    //   //     //url: '/admin/templates/doc',
    //   //     icon: 'icon-puzzle',
    //   //     permission: 'templates',
    //   //   },
    //   //   {
    //   //     name: 'Email',
    //   //     url: '/admin/clients',
    //   //     //url: '/admin/templates/email',
    //   //     icon: 'icon-puzzle',
    //   //     permission: 'templates',
    //   //   },
    //   //   {
    //   //     name: 'SMS',
    //   //     url: '/admin/clients',
    //   //     //url: '/admin/templates/sms',
    //   //     icon: 'icon-puzzle',
    //   //     permission: 'templates',
    //   //   },
    //   // ],
    // },
  ],
};
