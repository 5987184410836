import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { Link } from 'react-router-dom';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import Loader from '../../../shared/loader';

class ProceduresTable extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    districts: PropTypes.shape({}).isRequired,
    towns: PropTypes.shape({}).isRequired,
    areas: PropTypes.shape({}).isRequired,
    onTableChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
  }

  formatterId = (fio, row) => <Link to={`/admin/procedure/${row.id}`}>{row.code}.{row.number}</Link>;

  render() {
    const { onTableChange, data, page, sizePerPage, totalSize, isLoading, districts, towns, areas } = this.props;

    if (!Object.keys(districts).length || !Object.keys(towns).length || !Object.keys(areas).length) return '';

    this.columns = [
      { dataField: 'id', hidden: true },
      {
        headerStyle: { width: '100px' },
        dataField: 'code',
        text: 'Код',
        filter: selectFilter({
          options: { 200: 200, 156: 156 },
        }),
      },
      {
        headerStyle: { width: '120px' },
        dataField: 'number',
        text: 'Номер',
        sort: true,
        filter: textFilter(),
        formatter: this.formatterId,
      },
      { dataField: 'data', text: 'Наименование', filter: textFilter() },
      { dataField: 'ap_organ', text: 'Государственный орган (иная организация), в который гражданин должен обратиться', filter: textFilter() },
      { dataField: 'ap_document', text: 'Документы и (или) сведения, представляемые гражданином для осуществления административной процедуры', filter: textFilter() },
      { dataField: 'ap_money', text: 'Размер платы, взимаемой при осуществлении административной процедуры', filter: textFilter() },
      { dataField: 'ap_time', text: 'Максимальный срок осуществления административной процедуры', filter: textFilter() },
      {
        dataField: 'ap_valid',
        text: 'Срок действия справки, другого документа (решения), выдаваемых (принимаемого) при осуществлении административной процедуры',
        filter: textFilter(),
      },
    ];

    return (
      <BootstrapTable
        remote
        bootstrap4
        striped
        keyField="id"
        data={data}
        columns={this.columns}
        onTableChange={onTableChange}
        noDataIndication={() => isLoading && <Loader />}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        filter={filterFactory()}
      />
    );
  }
}

export default ProceduresTable;
