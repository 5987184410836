import { connect } from 'react-redux';

import { authSelectors, authActions } from '../duck';

import Login from './Login';
import ComponentWithError from '../../shared/HOCs/componentWithError';

const mapStateToProps = state => ({
  isFetching: authSelectors.isFetching(state),
  user: authSelectors.user(state),
  errors: authSelectors.errors(state),
});

const mapDispatchToProps = {
  login: authActions.login,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithError(Login));
