import { combineActions, createActions, handleActions } from 'redux-actions';

const { fetchDistricts, fetchDistrictsSuccess, fetchDistrictsFail } = createActions('FETCH_DISTRICTS', 'FETCH_DISTRICTS_SUCCESS', 'FETCH_DISTRICTS_FAIL');

const defaultState = {
  districts: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
};

const districtsReducer = handleActions(
  {
    [fetchDistricts]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchDistrictsSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchDistrictsFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.districts
);

export const reducer = { districts: districtsReducer };

export default {
  fetchDistricts,
  fetchDistrictsSuccess,
  fetchDistrictsFail,
};
