import React from 'react';

class NotificationsService {
  static notificationRef = React.createRef();

  static addSuccessNotification = message => {
    this.notificationRef.current.addNotification({
      message,
      level: 'success',
    });
  };

  static addErrorNotification = message => {
    this.notificationRef.current.addNotification({
      message,
      level: 'error',
    });
  };
}

export default NotificationsService;
