import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { Link } from 'react-router-dom';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';

import Loader from '../../../shared/loader';

class UnitsTable extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    districts: PropTypes.shape({}).isRequired,
    towns: PropTypes.shape({}).isRequired,
    areas: PropTypes.shape({}).isRequired,
    siks: PropTypes.shape({}).isRequired,
    onTableChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    sizePerPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };
  // un_ap.*,
  // ut.id_address_area,
  // ut.id_address_sik,

  constructor(props) {
    super(props);

    this.formatterId = this.formatterId.bind(this);
    this.formatterIdAddressArea = this.formatterIdAddressArea.bind(this);
    this.formatterIdAddressSik = this.formatterIdAddressSik.bind(this);
  }

  formatterId = (id, row) => {
    const { districts, areas, towns, siks } = this.props;

    let idDistrict = _get(siks, [row.id_address_sik, 'id_district'], -1);
    if (idDistrict === -1) {
      const idTown = _get(areas, [row.id_address_area, 'id_town'], -1);
      idDistrict = _get(towns, [idTown, 'id_district'], -1);
    }

    const districtName = _get(districts, [idDistrict], '');
    return <div>{districtName}</div>;
  };

  formatterIdAddressArea = idAddressArea => {
    const { areas } = this.props;

    return <div>{_get(areas, [idAddressArea, 'area'], '')}</div>;
  };

  formatterIdAddressSik = idAddressSik => {
    const { siks } = this.props;

    return <div>{_get(siks, [idAddressSik, 'sik'], '')}</div>;
  };

  render() {
    const { onTableChange, data, page, sizePerPage, totalSize, isLoading, districts, towns, areas, siks } = this.props;

    if (!Object.keys(districts).length || !Object.keys(towns).length || !Object.keys(areas).length || !Object.keys(siks).length) return '';

    this.columns = [
      {
        dataField: 'id',
        text: 'Район',
        formatter: this.formatterId,
      },
      {
        dataField: 'id_address_area',
        text: 'Подразделение',
        formatter: this.formatterIdAddressArea,
      },
      {
        dataField: 'id_address_sik',
        text: 'СИК',
        formatter: this.formatterIdAddressSik,
      },
      {
        headerStyle: { width: '100px' },
        dataField: 'code',
        text: 'Код',
        filter: selectFilter({
          options: { 200: 200, 156: 156 },
        }),
      },
      {
        headerStyle: { width: '120px' },
        dataField: 'number',
        text: 'Номер',
        sort: true,
        filter: textFilter(),
      },
      { dataField: 'data', text: 'Наименование процедуры', filter: textFilter() },
      { dataField: 'ap_address', text: 'Куда обращаться', filter: textFilter() },
      { dataField: 'ap_time', text: 'День недели; время приема', filter: textFilter() },
      { dataField: 'ap_tel', text: 'Телефон', filter: textFilter() },
      { dataField: 'ap_info', text: 'Дополнительная информация', filter: textFilter() },
    ];

    return (
      <BootstrapTable
        remote
        bootstrap4
        striped
        keyField="id"
        data={data}
        columns={this.columns}
        onTableChange={onTableChange}
        noDataIndication={() => isLoading && <Loader />}
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        filter={filterFactory()}
      />
    );
  }
}

export default UnitsTable;
