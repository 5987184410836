// Areas list

const isFetchingAreas = ({ areas }) => areas.isFetching;

const page = ({ areas }) => areas.page;

const sizePerPage = ({ areas }) => areas.sizePerPage;

const totalSize = ({ areas }) => areas.total;

const areasErrors = ({ areas }) => areas.errors;

const areas = ({ areas: v }) => v.items;

export default {
  isFetchingAreas,
  areas,
  page,
  totalSize,
  sizePerPage,
  areasErrors,
};
