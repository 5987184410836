// Towns list

const isFetchingTowns = ({ towns }) => towns.isFetching;

const page = ({ towns }) => towns.page;

const sizePerPage = ({ towns }) => towns.sizePerPage;

const totalSize = ({ towns }) => towns.total;

const townsErrors = ({ towns }) => towns.errors;

const towns = ({ towns: v }) => v.items;

export default {
  isFetchingTowns,
  towns,
  page,
  totalSize,
  sizePerPage,
  townsErrors,
};
