import { createActions, handleActions } from 'redux-actions';

const { fetchAreas, fetchAreasSuccess, fetchAreasFail } = createActions('FETCH_AREAS', 'FETCH_AREAS_SUCCESS', 'FETCH_AREAS_FAIL');

const defaultState = {
  areas: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
};

const areasReducer = handleActions(
  {
    [fetchAreas]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [fetchAreasSuccess]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [fetchAreasFail]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.areas
);

export const reducer = { areas: areasReducer };

export default {
  fetchAreas,
  fetchAreasSuccess,
  fetchAreasFail,
};
