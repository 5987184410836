import PropTypes from 'prop-types';

import hasPermission from '../utils';

const Can = ({ permission, role, yes, no }) => (hasPermission(permission, role) ? yes() : no());

Can.propTypes = {
  permission: PropTypes.string.isRequired,
  role: PropTypes.oneOf(['admin', 'client', 'doctor', 'manager']).isRequired,
  yes: PropTypes.func,
  no: PropTypes.func,
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
