import { put, call, fork, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import unitOperations from './operations';
import unitActions from './modules';

import NotificationsService from '../../../services/notifications.service';

function* fetchUnits({ payload }) {
  const { errors, response } = yield call(unitOperations.fetchUnits, payload);

  if (response) {
    const units = _get(response, 'data.units.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.units', {});
    yield put(unitActions.fetchUnitsSuccess({ items: units, sizePerPage, page, total }));
  } else {
    yield put(unitActions.fetchUnitsFail(errors));
  }
}

function* watchUnitsFetch() {
  yield takeLatest(unitActions.fetchUnits, fetchUnits);
}

function* fetchUnit({ payload: { id } }) {
  const { errors, response } = yield call(unitOperations.fetchUnit, id);
  const unit = _get(response, 'data.unit', null);

  if (response) {
    yield put(unitActions.fetchUnitSuccess({ unit }));
  } else {
    yield put(unitActions.fetchUnitFail(errors));
  }
}

function* watchUnitFetch() {
  yield takeLatest(unitActions.fetchUnit, fetchUnit);
}

function* editUnit({ payload }) {
  const { errors, response } = yield call(unitOperations.editUnit, payload);

  const unit = _get(response, 'data.unit', null);

  if (response) {
    NotificationsService.addSuccessNotification('Updated successfully');
    yield put(unitActions.editUnitSuccess({ unit }));
  } else {
    yield put(unitActions.editUnitFail(errors));
  }
}

function* watchUnitEdit() {
  yield takeLatest(unitActions.editUnit, editUnit);
}

export default function* watchAll() {
  yield fork(watchUnitsFetch);
  yield fork(watchUnitFetch);
  yield fork(watchUnitEdit);
}
