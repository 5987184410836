import { combineActions, createActions, handleActions } from 'redux-actions';

const {
  fetchDeputies,
  fetchDeputiesSuccess,
  fetchDeputiesFail,
  fetchDeputy,
  fetchDeputySuccess,
  fetchDeputyFail,
  editDeputy,
  editDeputySuccess,
  editDeputyFail,
  createDeputy,
  createDeputySuccess,
  createDeputyFail,
  removeDeputy,
  removeDeputySuccess,
  removeDeputyFail,
  removeDeputyPhoto,
  removeDeputyPhotoSuccess,
  removeDeputyPhotoFail,
} = createActions(
  'FETCH_DEPUTIES',
  'FETCH_DEPUTIES_SUCCESS',
  'FETCH_DEPUTIES_FAIL',
  'FETCH_DEPUTY',
  'FETCH_DEPUTY_SUCCESS',
  'FETCH_DEPUTY_FAIL',
  'EDIT_DEPUTY',
  'EDIT_DEPUTY_SUCCESS',
  'EDIT_DEPUTY_FAIL',
  'CREATE_DEPUTY',
  'CREATE_DEPUTY_SUCCESS',
  'CREATE_DEPUTY_FAIL',
  'REMOVE_DEPUTY',
  'REMOVE_DEPUTY_SUCCESS',
  'REMOVE_DEPUTY_FAIL',
  'REMOVE_DEPUTY_PHOTO',
  'REMOVE_DEPUTY_PHOTO_SUCCESS',
  'REMOVE_DEPUTY_PHOTO_FAIL'
);

const defaultState = {
  deputies: {
    items: [],
    isFetching: false,
    errors: [],
    page: 1,
    sizePerPage: 0,
    total: 0,
  },
  deputy: {
    isFetching: false,
    isUpdating: false,
    errors: [],
  },
};

const deputyReducer = handleActions(
  {
    [combineActions(fetchDeputy)]: state => ({
      ...state,
      ...defaultState.deputy,
      isFetching: true,
    }),
    [combineActions(editDeputy, createDeputy, removeDeputy, removeDeputyPhoto)]: state => ({
      ...state,
      isUpdating: true,
    }),
    [combineActions(fetchDeputySuccess, editDeputySuccess, createDeputySuccess, removeDeputySuccess, removeDeputyPhotoSuccess)]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
      isUpdating: false,
    }),
    [combineActions(fetchDeputyFail, editDeputyFail, createDeputyFail, removeDeputyFail, removeDeputyPhotoFail)]: (state, action) => ({
      ...state,
      isFetching: false,
      isUpdating: false,
      errors: action.payload,
    }),
  },
  defaultState.deputy
);

const deputiesReducer = handleActions(
  {
    [combineActions(fetchDeputies)]: state => ({
      ...state,
      isFetching: true,
      items: [],
    }),
    [combineActions(fetchDeputiesSuccess)]: (state, action) => ({
      ...state,
      ...action.payload,
      errors: [],
      isFetching: false,
    }),
    [combineActions(fetchDeputiesFail)]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload,
    }),
  },
  defaultState.deputies
);

export const reducer = { deputy: deputyReducer, deputies: deputiesReducer };

export default {
  fetchDeputies,
  fetchDeputiesSuccess,
  fetchDeputiesFail,
  fetchDeputy,
  fetchDeputySuccess,
  fetchDeputyFail,
  editDeputy,
  editDeputySuccess,
  editDeputyFail,
  createDeputy,
  createDeputySuccess,
  createDeputyFail,
  removeDeputy,
  removeDeputySuccess,
  removeDeputyFail,
  removeDeputyPhoto,
  removeDeputyPhotoSuccess,
  removeDeputyPhotoFail,
};
