import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

import { AppSidebarToggler } from '@coreui/react';

import Logout from '../../auth/Logout';
import AccountDropdown from './AccountDropdown';

class Header extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  state = {
    isLoggedOut: false,
  };

  onLogout = () => {
    this.setState({ isLoggedOut: true });
  };

  render() {
    const { user } = this.props;
    const { isLoggedOut } = this.state;

    return isLoggedOut ? (
      <Logout withServerLogout />
    ) : (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />

        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <AccountDropdown onLogout={this.onLogout} user={user} />
        </Nav>
      </React.Fragment>
    );
  }
}

export default Header;
