import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import authReducer from '../app/auth/duck';
import officerReducers from '../app/officers/duck';
import deputyReducers from '../app/deputies/duck';
import districtReducers from '../app/districts/duck';
import townReducers from '../app/towns/duck';
import areaReducers from '../app/areas/duck';
import sikReducers from '../app/siks/duck';
import procedureReducers from '../app/procedures/duck';
import unitReducers from '../app/units/duck';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [],
};

const reducer = combineReducers({
  auth: authReducer,
  ...officerReducers,
  ...deputyReducers,
  ...districtReducers,
  ...townReducers,
  ...areaReducers,
  ...sikReducers,
  ...procedureReducers,
  ...unitReducers,
});

const pReducer = persistReducer(persistConfig, reducer);

export default pReducer;
