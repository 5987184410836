// Siks list

const isFetchingSiks = ({ siks }) => siks.isFetching;

const page = ({ siks }) => siks.page;

const sizePerPage = ({ siks }) => siks.sizePerPage;

const totalSize = ({ siks }) => siks.total;

const siksErrors = ({ siks }) => siks.errors;

const siks = ({ siks: v }) => v.items;

export default {
  isFetchingSiks,
  siks,
  page,
  totalSize,
  sizePerPage,
  siksErrors,
};
