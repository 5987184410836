import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { officerActions, officerSelectors } from '../duck';

import OfficerCreate from './OfficerCreate';
import ComponentWithError from '../../shared/HOCs/componentWithError';
import { districtActions, districtSelectors } from '../../districts/duck';
import { areaActions, areaSelectors } from '../../areas/duck';
import { townActions, townSelectors } from '../../towns/duck';

const mapStateToProps = state => ({
  isFetching: officerSelectors.isFetchingOfficer(state),
  errors: officerSelectors.officerErrors(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
});

const mapDispatchToProps = {
  createOfficer: officerActions.createOfficer,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithError(OfficerCreate))
);
