import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

const ModalSuccess = ({ isOpen, className, onClose, onClickOk, onClickCancel, strHeader, strBody, strButtonOk, strButtonCancel }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} className={className}>
      <ModalHeader toggle={onClose}>
        <i className="cui-settings mr-2" />
        <strong>{strHeader}</strong>
      </ModalHeader>
      <ModalBody>{strBody}</ModalBody>
      <ModalFooter>
        <Button color="success" onClick={onClickOk}>
          {strButtonOk}
        </Button>{' '}
        <Button color="secondary" onClick={onClickCancel}>
          {strButtonCancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalSuccess.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickOk: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,

  strHeader: PropTypes.string,
  strBody: PropTypes.string,
  strButtonOk: PropTypes.string,
  strButtonCancel: PropTypes.string,
};

ModalSuccess.defaultProps = {
  className: 'modal-warning',
  strHeader: '',
  strBody: '',
  strButtonOk: 'Да',
  strButtonCancel: 'Закрыть',
};

export default ModalSuccess;
