import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { get as _get } from 'lodash';

import officerOperations from './operations';
import officerActions from './modules';

import NotificationsService from '../../../services/notifications.service';

function* fetchOfficers({ payload }) {
  const { errors, response } = yield call(officerOperations.fetchOfficers, payload);

  if (response) {
    const officers = _get(response, 'data.officers.items', null);
    const { limit: sizePerPage, page, total } = _get(response, 'data.officers', {});
    yield put(officerActions.fetchOfficersSuccess({ items: officers, sizePerPage, page, total }));
  } else {
    yield put(officerActions.fetchOfficersFail(errors));
  }
}

function* watchOfficersFetch() {
  yield takeLatest(officerActions.fetchOfficers, fetchOfficers);
}

function* fetchOfficer({ payload: { id } }) {
  const { errors, response } = yield call(officerOperations.fetchOfficer, id);

  if (response) {
    const officer = _get(response, 'data.officer', null);
    yield put(officerActions.fetchOfficerSuccess(officer));
  } else {
    yield put(officerActions.fetchOfficerFail(errors));
  }
}

function* watchOfficerFetch() {
  yield takeLatest(officerActions.fetchOfficer, fetchOfficer);
}

function* editOfficer({ payload }) {
  const { errors, response } = yield call(officerOperations.editOfficer, payload);

  const officer = _get(response, 'data.officer', null);

  if (response) {
    NotificationsService.addSuccessNotification('Updated successfully');
    yield put(officerActions.editOfficerSuccess(officer));
  } else {
    yield put(officerActions.editOfficerFail(errors));
  }
}

function* watchOfficerEdit() {
  yield takeLatest(officerActions.editOfficer, editOfficer);
}

function* createOfficer({ payload }) {
  const { errors, response } = yield call(officerOperations.createOfficer, payload.data);

  const officer = _get(response, 'data.officer', null);

  if (response) {
    NotificationsService.addSuccessNotification('Create successfully');
    yield put(officerActions.createOfficerSuccess(officer));
    payload.response(officer);
  } else {
    yield put(officerActions.createOfficerFail(errors));
  }
}

function* watchOfficerCreate() {
  yield takeLatest(officerActions.createOfficer, createOfficer);
}

function* removeOfficer({ payload }) {
  const { errors, response } = yield call(officerOperations.removeOfficer, payload.id);

  if (response) {
    NotificationsService.addSuccessNotification('Remove successfully');
    yield put(officerActions.removeOfficerSuccess({}));
    payload.response();
  } else {
    yield put(officerActions.removeOfficerFail(errors));
  }
}

function* watchOfficerRemove() {
  yield takeLatest(officerActions.removeOfficer, removeOfficer);
}

function* removeOfficerPhoto({ payload: { id } }) {
  const { errors, response } = yield call(officerOperations.removeOfficerPhoto, id);

  if (response) {
    NotificationsService.addSuccessNotification('Remove successfully');
    const officer = _get(response, 'data.officer', null);
    yield put(officerActions.removeOfficerPhotoSuccess(officer));
  } else {
    yield put(officerActions.removeOfficerPhotoFail(errors));
  }
}

function* watchOfficerPhotoRemove() {
  yield takeLatest(officerActions.removeOfficerPhoto, removeOfficerPhoto);
}

export default function* watchAll() {
  yield fork(watchOfficersFetch);
  yield fork(watchOfficerFetch);
  yield fork(watchOfficerEdit);
  yield fork(watchOfficerCreate);
  yield fork(watchOfficerRemove);
  yield fork(watchOfficerPhotoRemove);
}
