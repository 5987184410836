import base64 from 'base-64';
import { get as _get } from 'lodash';

export const serverErrorFormatter = errors => (Array.isArray(errors) ? errors.map(error => ({ ...error, message: error.message || error })) : [{ message: errors }]);

export const toBase64 = str => base64.encode(str);

export const toSelectOptions = (optionsList, labelKey = '', valueKey = '') =>
  optionsList.map(option => {
    const label = labelKey ? option[labelKey] : option;
    const value = valueKey ? option[valueKey] : option;

    return { label, value };
  });

export const toSelectOptionsV2 = (optionsList, labelKey = null, valueKey = '') =>
  optionsList.reduce((obj, e) => ({ ...obj, [_get(e, valueKey, '')]: labelKey ? _get(e, labelKey, '') : e }), {});

export const getSelectOption = (optionsList, optionValue) => optionsList.find(({ label, value }) => value === optionValue);

export const formatTableRespone = ({ filters, page, sortField, sortOrder, sizePerPage }) => {
  const formattedFilters = {};

  Object.keys(filters).forEach(filterKey => (formattedFilters[filterKey] = filters[filterKey].filterVal));

  return { filters: formattedFilters, page, sortField, sortOrder, limit: sizePerPage };
};

export const areaToSelectOptionsV2 = (areas, towns, districts) => {
  const townsO = toSelectOptionsV2(towns, null, 'id');
  const districtsO = toSelectOptionsV2(districts, 'district', 'id');

  return areas.reduce((obj, e) => {
    const idAddressArea = _get(e, 'id', '');
    const idTown = _get(e, 'id_town', -1);
    const idDistrict = _get(townsO, [idTown, 'id_district'], -1);
    const districtName = _get(districtsO, idDistrict, '');

    return { ...obj, [idAddressArea]: `${districtName} : ${_get(e, 'area', '')}` };
  }, {});
};

export const districtToArea = (areas, towns) => {
  const townsO = toSelectOptionsV2(towns, null, 'id');

  return areas.reduce((obj, e) => {
    const idAddressArea = _get(e, 'id', '');
    const idTown = _get(e, 'id_town', -1);
    const idDistrict = _get(townsO, [idTown, 'id_district'], -1);

    return { ...obj, [idDistrict]: idAddressArea };
  }, {});
};

export const areaToDistrict = (areas, towns) => {
  const townsO = toSelectOptionsV2(towns, null, 'id');

  return areas.reduce((obj, e) => {
    const idAddressArea = _get(e, 'id', '');
    const idTown = _get(e, 'id_town', -1);
    const idDistrict = _get(townsO, [idTown, 'id_district'], -1);

    return { ...obj, [idAddressArea]: idDistrict };
  }, {});
};

export const areaToSelectOptionsV3 = (areas, towns, districts) => {
  const townsO = toSelectOptionsV2(towns, null, 'id');
  const districtsO = toSelectOptionsV2(districts, 'district', 'id');

  return areas.map(e => {
    const idTown = _get(e, 'id_town', -1);
    const idDistrict = _get(townsO, [idTown, 'id_district'], -1);
    const districtName = _get(districtsO, idDistrict, '');
    const label = `${districtName} : ${_get(e, 'area', '')}`;
    const value = _get(e, 'id', '').toString();

    return { label, value };
  });
};
