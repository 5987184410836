import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <span>142.бел &copy; 2019</span>
      </React.Fragment>
    );
  }
}

export default Footer;
