import fetch from '../../../services/fetch-api.service';

const fetchUnits = ({ filters, page, sortField, sortOrder, limit }) => fetch.get('admin/units/list', { filters, page, sortField, sortOrder, limit });

const fetchUnit = id => fetch.get(`admin/unit/view/${id}`);

const editUnit = ({ id, data }) => fetch.post(`admin/unit/edit/${id}`, null, data);

export default {
  fetchUnits,
  fetchUnit,
  editUnit,
};
