import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { deputyActions, deputySelectors } from '../duck';

import DeputyCreate from './DeputyCreate';
import ComponentWithError from '../../shared/HOCs/componentWithError';
import { districtActions, districtSelectors } from '../../districts/duck';
import { areaActions, areaSelectors } from '../../areas/duck';
import { townActions, townSelectors } from '../../towns/duck';

const mapStateToProps = state => ({
  isFetching: deputySelectors.isFetchingDeputy(state),
  errors: deputySelectors.deputyErrors(state),
  districts: districtSelectors.districts(state),
  towns: townSelectors.towns(state),
  areas: areaSelectors.areas(state),
});

const mapDispatchToProps = {
  createDeputy: deputyActions.createDeputy,
  fetchDistricts: districtActions.fetchDistricts,
  fetchTowns: townActions.fetchTowns,
  fetchAreas: areaActions.fetchAreas,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithError(DeputyCreate))
);
