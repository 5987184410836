import fetch from '../../../services/fetch-api.service';

const fetchProcedures = ({ filters, page, sortField, sortOrder, limit }) => fetch.get('admin/procedures/list', { filters, page, sortField, sortOrder, limit });

const fetchProcedure = id => fetch.get(`admin/procedure/view/${id}`);

const editProcedure = ({ id, data }) => fetch.post(`admin/procedure/edit/${id}`, null, data);

export default {
  fetchProcedures,
  fetchProcedure,
  editProcedure,
};
