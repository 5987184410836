import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import { AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav } from '@coreui/react';

import navigation from '../../_nav';

import hasPermission from '../../permissions/utils';

import Footer from './Footer';
import Header from './Header';

class Layout extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    role: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.navConfig = navigation.items.filter(conf => hasPermission(conf.permission, props.role));
  }

  render() {
    const { children, user } = this.props;

    return (
      <div className="app">
        <AppHeader fixed>
          <Header user={user} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={{ items: this.navConfig }} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Container style={{ marginTop: '20px' }} fluid>
              {children}
            </Container>
          </main>
        </div>
        <AppFooter>
          <Footer/>
        </AppFooter>
      </div>
    );
  }
}

export default Layout;
